import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import {
  Button,
  Modal,
  Grid,
  Icon,
  Form,
  Search,
  Label,
  Popup,
  Input,
} from "semantic-ui-react";
import OrdersInfoModal from "./OrdersInfoModal";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import swal from "sweetalert2";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import "moment/locale/es";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { exportToXls } from "../../utils/Utils";
import Toggle from "react-toggle";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderSearchSaeResults = ({ id, ref_sae }) => (
  <Label key={id} content={ref_sae} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

class OrdersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      total: 0,
      page: 0,
      loading: false,
      currentRow: {},
      isInfoOpened: false,
      isEditOpened: false,
      pageSize: 100,
      filterOptions: {
        id: null,
        status_id: null,
        status_options: [],
        fecha_alta: "",
        fecha_entrega: "",
        ref_sae: null,
        pedido_id: null,
        partida_id: null,
      },
      sae: null,
      mancheta_id: null,
      results: null,
      client: "",
      $sort: {
        $fechaAltaSortDirection: null,
        $fechaEntregaSortDirection: null,
        $saeSortDirection: null,
      },
      interval: undefined,
      saeLoading: null,
      saeResults: null,
      columna: "",
      sortDirection: false,
      showSae: false,
      shouldExport: false,
      tableColumns: [
        {
          Header: "SAE",
          id: "ref_sae",
          maxWidth: 75,
          accessor: (d) => d.ref_sae,
        },
        {
          Header: "Partida",
          headerStyle: { fontSize: 16 },
          maxWidth: 95,
          id: "partida_id",
          accessor: (d) => {
            if (d.partidas) return d.partidas.id;
            return "---";
          },
        },
        // {
        //   Header: "Rodillo",
        //   headerStyle: { fontSize: 16 },
        //   id: "rodillo_id",
        //   accessor: (d) => {
        //     if (d.partidas) return d.partidas.id;
        //     return "---";
        //   },
        // },
        { Header: "Cliente", accessor: "cliente.nombre" },
        /* {
          Header: "Fecha Alta",
          id: "fecha_alta",
          accessor: (d) => new Date(d.fecha_alta).toLocaleDateString(),
        }, */
        {
          Header: "Fecha Entrega",
          id: "fecha_entrega",
          maxWidth: 95,
          accessor: (d) => new Date(d.fecha_entrega).toLocaleDateString(),
        },
        /* {
          Header: "1er Producto",
          id: "p-producto",
          accessor: (d) => {
            if (!d.partidas) {
              return "---";
            }
            if (d.partidas.length) {
              return d.partidas[0].producto.nombre;
            } else {
              return d.partidas.producto.nombre;
            }
          },
        }, */
        {
          Header: "Material",
          id: "material",
          maxWidth: 100,
          accessor: (d) => {
            if (!d.partidas) {
              return "---";
            }
            if (d.partidas.length) {
              if (d.partidas[0].material) {
                return d.partidas[0].material.nombre;
              } else {
                return "---";
              }
            } else if (d.partidas.material) {
              return d.partidas.material.nombre;
            } else {
              return "---";
            }
          },
        },
        /* {
          Header: "Cantidad",
          id: "p-cantidades",
          maxWidth: 100,
          accessor: (d) => {
            if (!d.partidas) {
              return "---";
            }

            if (d.partidas.length) {
              return d.partidas[0].cantidad;
            } else {
              return d.partidas.cantidad;
            }
          },
        }, */
        {
          Header: "Dureza",
          //accessor: "partidas.dureza.valor",
          id: "dureza",
          maxWidth: 80,
          accessor: (d) => {
            if (!d.partidas) {
              return "---";
            }
            if (d.partidas.length) {
              if (d.partidas[0].dureza) {
                return d.partidas[0].dureza.valor;
              } else {
                return "---";
              }
            } else if (d.partidas.dureza) {
              return d.partidas.dureza.valor;
            } else {
              return "---";
            }
          },
        },
        /* {
          Header: "P. Mancheta",
          accessor: "partidas.manchetas.parte",
          maxWidth: 100,
        }, */
        {
          Header: "DN",
          //accessor: "partidas.dureza.valor",
          id: "dn",
          maxWidth: 80,
          accessor: (d) => {
            if (!d.partidas) {
              return "---";
            }
            if (d.partidas.length) {
              return d.partidas[0].dn;
            } else if (d.partidas) {
              return d.partidas.dn;
            } else {
              return "---";
            }
          },
        },
        {
          Header: "DE",
          //accessor: "partidas.dureza.valor",
          id: "de",
          maxWidth: 80,
          accessor: (d) => {
            if (!d.partidas) {
              return "---";
            }
            if (d.partidas.length) {
              return d.partidas[0].de;
            } else if (d.partidas) {
              return d.partidas.de;
            } else {
              return "---";
            }
          },
        },
        {
          Header: "LC",
          //accessor: "partidas.dureza.valor",
          id: "lc",
          maxWidth: 80,
          accessor: (d) => {
            if (!d.partidas) {
              return "---";
            }
            if (d.partidas.length) {
              return d.partidas[0].lc;
            } else if (d.partidas) {
              return d.partidas.lc;
            } else {
              return "---";
            }
          },
        },
        {
          Header: "LT",
          //accessor: "partidas.dureza.valor",
          id: "lt",
          maxWidth: 80,
          accessor: (d) => {
            if (!d.partidas) {
              return "---";
            }
            if (d.partidas.length) {
              return d.partidas[0].lt;
            } else if (d.partidas) {
              return d.partidas.lt;
            } else {
              return "---";
            }
          },
        },
        {
          Header: "Kilos",
          //accessor: "partidas.dureza.valor",
          id: "kilos",
          maxWidth: 80,
          accessor: (d) => {
            if (!d.partidas) {
              return "---";
            }
            if (d.partidas.length) {
              return d.partidas[0].kilos;
            } else if (d.partidas) {
              return d.partidas.kilos;
            } else {
              return "---";
            }
          },
        },
        {
          Header: "Status",
          id: "status",
          accessor: (d) => {
            if (d.status_id === 1)
              return (
                <Label basic color="grey">
                  <Icon name="check" /> {d.status.nombre}{" "}
                </Label>
              );
            if (d.status_id === 2)
              return (
                <Label basic color="blue">
                  <Icon name="check" /> {d.status.nombre}{" "}
                </Label>
              );
            if (d.status_id === 3)
              return (
                <Label basic color="green">
                  <Icon name="check" /> {d.status.nombre}{" "}
                </Label>
              );
            if (d.status_id === 4)
              return (
                <Label basic color="green">
                  <Icon name="check" /> {d.status.nombre}{" "}
                </Label>
              );
            if (d.status_id === 5)
              return (
                <Label basic color="green">
                  <Icon name="check" /> {d.status.nombre}{" "}
                </Label>
              );
            if (d.status_id === 6)
              return (
                <Label basic color="red">
                  <Icon name="check" /> {d.status.nombre}{" "}
                </Label>
              );
          },
        },
        {
          Header: "Etapa",
          id: "stage",
          accessor: (d) => {
            let rodilloStage;
            let firstRodilloId;

            if (d.partidas) {
              if (d.partidas.length) {
                // -1 if rodillo stage doesnt exist
                rodilloStage = d.partidas[0].rodillo
                  ? d.partidas[0].rodillo.length
                    ? d.partidas[0].rodillo[0].stage
                    : d.partidas[0].rodillo.stage
                  : -1;

                firstRodilloId = d.partidas[0].rodillo
                  ? d.partidas[0].rodillo.length
                    ? d.partidas[0].rodillo[0].id
                    : d.partidas[0].rodillo.id
                  : -1;
              } else {
                if (d.partidas.rodillo) {
                  if (d.partidas.rodillo.length) {
                    rodilloStage = d.partidas.rodillo[0]
                      ? d.partidas.rodillo[0].stage
                      : -1;

                    firstRodilloId = d.partidas.rodillo[0]
                      ? d.partidas.rodillo[0].id
                      : -1;
                  } else {
                    // -1 if rodillo stage doesnt exist
                    rodilloStage = d.partidas.rodillo
                      ? d.partidas.rodillo.stage
                      : -1;
                    firstRodilloId = d.partidas.rodillo
                      ? d.partidas.rodillo.id
                      : -1;
                  }
                } else {
                  // -1 if rodillo stage doesnt exist
                  rodilloStage = d.partidas.rodillo
                    ? d.partidas.rodillo.stage
                    : -1;
                  firstRodilloId = d.partidas.rodillo
                    ? d.partidas.rodillo.id
                    : -1;
                }
              }
            }

            switch (rodilloStage) {
              case 0:
                return (
                  <Label basic color="blue">
                    <Icon name="book" /> Incidencia #{firstRodilloId}
                  </Label>
                );
              case 1:
                return (
                  <Label basic color="blue">
                    <Icon name="doctor" /> Desviste #{firstRodilloId}
                  </Label>
                );
              case 2:
                break;
              case 3:
                return (
                  <Label basic color="blue">
                    <Icon name="play" /> Molino #{firstRodilloId}
                  </Label>
                );
              case 4:
                return (
                  <Label basic color="blue">
                    <Icon name="building" /> Vulcanizado #{firstRodilloId}
                  </Label>
                );
              case 5:
                return (
                  <Label basic color="blue">
                    <Icon name="building" /> Desmonte #{firstRodilloId}
                  </Label>
                );
              case 6:
                return (
                  <Label basic color="blue">
                    <Icon name="building" /> Desbaste #{firstRodilloId}
                  </Label>
                );
              case 7:
                return (
                  <Label basic color="blue">
                    <Icon name="building" /> Rectificado #{firstRodilloId}
                  </Label>
                );
              case 8:
                return (
                  <Label basic color="blue">
                    <Icon name="cut" /> Corte #{firstRodilloId}
                  </Label>
                );
              case 9:
                return (
                  <Label basic color="blue">
                    <Icon name="building" /> Construcción #{firstRodilloId}
                  </Label>
                );
              case 10:
                return (
                  <Label basic color="blue">
                    <Icon name="building" /> Finalizado #{firstRodilloId}
                  </Label>
                );
              case 11:
                return (
                  <Label basic color="blue">
                    <Icon name="building" /> Pend. Vulcanizado #{firstRodilloId}
                  </Label>
                );

              default:
                break;
            }
          },
        },
        {
          Header: "Acciones",
          sortable: false,
          Cell: (row) => (
            <div>
              <Popup
                content="Ver pedido"
                trigger={
                  <Button
                    size="mini"
                    compact
                    basic
                    color="blue"
                    icon="eye"
                    onClick={() => this.openInfoModal(row)}
                  />
                }
              />
              <Popup
                content="Editar pedido"
                trigger={
                  <Button
                    disabled={row.original.status_id != 1}
                    size="mini"
                    compact
                    basic
                    color="green"
                    icon="pencil"
                    onClick={() => this._handleEditButton(row.original)}
                  />
                }
              />
              {/* <Popup 
                content="Generar factura" 
                trigger={
                  <Button
                    size="mini"
                    compact
                    basic
                    color="orange"
                    icon="file pdf outline"
                    onClick={() => this.createInvoice(row.original)}
                  />
                } 
              />
              {row.original.status_id === 3 ? (
                <Popup 
                  content="Generar Remisión" 
                  trigger={
                    <Button
                      size="mini"
                      compact
                      basic
                      color="brown"
                      icon="file pdf outline"
                      onClick={() => this.createRemission(row.original)}
                    />
                  } 
                />
              ) : (
                <></>
              )} */}
              <Popup
                content="Validar pedido"
                trigger={
                  <Button
                    disabled={
                      row.original.status_id != 1 ||
                      this.validateProduct(row.original)
                    }
                    size="mini"
                    compact
                    basic
                    color="pink"
                    icon="flag checkered"
                    onClick={() => this._handleInstaValidate(row.original)}
                  />
                }
              />
              <Popup
                content="Cancelar pedido"
                trigger={
                  <Button
                    size="mini"
                    disabled={row.original.status_id === 2}
                    compact
                    basic
                    color="red"
                    icon="close"
                    onClick={() => this._handleOnDelete(row.original)}
                  />
                }
              />

              {row.original.is_valid ? (
                this.validateDate(row.original)
              ) : (
                <Popup
                  trigger={<Icon circular color="red" name="alarm" />}
                  content="La fecha de entrega está fuera del período establecido"
                  size="large"
                />
              )}
            </div>
          ),
        },
      ],
    };

    this.fetchData = this.fetchData.bind(this);
    this.openInfoModal = this.openInfoModal.bind(this);
    this.closeInfoModal = this.closeInfoModal.bind(this);
    this.onValidateOrder = this.onValidateOrder.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
    this.fetchExportXls = this.fetchExportXls.bind(this);
  }

  handleSaeToggle = () => {
    this.setState({
      showSae: !this.state.showSae,
    });
    console.log(this.state.showSae);
  };

  componentDidMount() {
    this.getStatusFilterOptions();
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "SAE":
        array.data.sort((a, b) => {
          return a.ref_sae - b.ref_sae;
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha Entrega":
        array.data.sort((a, b) => {
          const nameA = a.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          const nameB = b.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Material":
        array.data.sort((a, b) => {
          let nameA = a.partidas.length
            ? a.partidas[0].material
              ? a.partidas[0].material.nombre.toUpperCase()
              : "0 "
            : a.partidas.material
            ? a.partidas.material.nombre.toUpperCase()
            : "0";
          let nameB = b.partidas.length
            ? b.partidas[0].material
              ? b.partidas[0].material.nombre.toUpperCase()
              : "0 "
            : b.partidas.material
            ? b.partidas.material.nombre.toUpperCase()
            : "0";

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A = a.partidas.length
            ? a.partidas[0].dureza
              ? a.partidas[0].dureza.valor
              : "0 "
            : a.partidas.dureza
            ? a.partidas.dureza.valor
            : "0";
          let B = b.partidas.length
            ? b.partidas[0].dureza
              ? b.partidas[0].dureza.valor
              : "0 "
            : b.partidas.dureza
            ? b.partidas.dureza.valor
            : "0";
          return parseFloat(A) - parseFloat(B);
        });
        break;
      case "DN":
        array.data.sort((a, b) => {
          let A = a.partidas.length
            ? a.partidas[0].dn
              ? a.partidas[0].dn
              : "0 "
            : a.partidas.dn
            ? a.partidas.dn
            : "0";
          let B = b.partidas.length
            ? b.partidas[0].dn
              ? b.partidas[0].dn
              : "0 "
            : b.partidas.dn
            ? b.partidas.dn
            : "0";
          return parseFloat(A) - parseFloat(B);
        });
        break;
      case "DE":
        array.data.sort((a, b) => {
          let A = a.partidas.length
            ? a.partidas[0].de
              ? a.partidas[0].de
              : "0 "
            : a.partidas.de
            ? a.partidas.de
            : "0";
          let B = b.partidas.length
            ? b.partidas[0].de
              ? b.partidas[0].de
              : "0 "
            : b.partidas.de
            ? b.partidas.de
            : "0";
          return parseFloat(A) - parseFloat(B);
        });
        break;
      case "LC":
        array.data.sort((a, b) => {
          let A = a.partidas.length
            ? a.partidas[0].lc
              ? a.partidas[0].lc
              : "0 "
            : a.partidas.lc
            ? a.partidas.lc
            : "0";
          let B = b.partidas.length
            ? b.partidas[0].lc
              ? b.partidas[0].lc
              : "0 "
            : b.partidas.lc
            ? b.partidas.lc
            : "0";
          return parseFloat(A) - parseFloat(B);
        });
        break;
      case "LT":
        array.data.sort((a, b) => {
          let A = a.partidas.length
            ? a.partidas[0].lt
              ? a.partidas[0].lt
              : "0 "
            : a.partidas.lt
            ? a.partidas.lt
            : "0";
          let B = b.partidas.length
            ? b.partidas[0].lt
              ? b.partidas[0].lt
              : "0 "
            : b.partidas.lt
            ? b.partidas.lt
            : "0";
          return parseFloat(A) - parseFloat(B);
        });
        break;
      case "Kilos":
        array.data.sort((a, b) => {
          let A = a.partidas.length
            ? a.partidas[0].kilos
              ? a.partidas[0].kilos
              : "0 "
            : a.partidas.kilos
            ? a.partidas.kilos
            : "0";
          let B = b.partidas.length
            ? b.partidas[0].kilos
              ? b.partidas[0].kilos
              : "0 "
            : b.partidas.kilos
            ? b.partidas.kilos
            : "0";
          return parseFloat(A) - parseFloat(B);
        });
        break;
      case "Status":
        array.data.sort((a, b) => {
          let A = a.status_id;
          let B = b.status_id;
          return parseFloat(A) - parseFloat(B);
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  getStatusFilterOptions = async (_) => {
    let status_pedido = await Feathers.service("status_pedido").find({
      query: {
        $limit: -1,
        activo: 1,
      },
    });
    status_pedido.push({ id: 7, nombre: "Todos", activo: true });
    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        status_options: status_pedido.map((v) => {
          return {
            key: v.id,
            text: v.nombre,
            value: v.id,
          };
        }),
      },
    });
  };

  fetchExportXls() {
    let query = {
      $limit: 100,
    };

    this.setState({ loading: true }, () => {
      Feathers.service("pedido")
        .find({
          query,
        })
        .then((res) => {
          let filters = {
            cliente_id: this.state.filterOptions.id,
            status_id: this.state.filterOptions.status_id,
            fecha_alta: this.state.filterOptions.fecha_alta
              ? moment(this.state.filterOptions.fecha_alta).format("YYYY-MM-DD")
              : null,
            fecha_entrega: this.state.filterOptions.fecha_entrega
              ? moment(this.state.filterOptions.fecha_entrega).format(
                  "YYYY-MM-DD"
                )
              : null,
            ref_sae: this.state.filterOptions.ref_sae,
            partida_id: this.state.filterOptions.partida_id,
          };
          console.log("----- filters front", filters);
          exportToXls([], "admin_orders", filters);
          this.setState({
            loading: false,
          });
        });
    });
  }

  async fetchData(state = {}) {
    let pageSize = state.pageSize || 100;
    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      // status_id: {
      //   $in: [1],
      // },
      $sort: {
        id: -1,
      },
    };

    const filtersXls = {};

    if (this.state.$sort.$fechaAltaSortDirection !== null) {
      query.$sort["fecha_alta"] = this.state.$sort.$fechaAltaSortDirection;
    }

    if (this.state.$sort.$fechaEntregaSortDirection !== null) {
      query.$sort[
        "fecha_entrega"
      ] = this.state.$sort.$fechaEntregaSortDirection;
    }

    if (this.state.$sort.$saeSortDirection !== null) {
      query.$sort = {
        ref_sae: this.state.$sort.$saeSortDirection,
      };
    }

    if (this.state.filterOptions.id) {
      query["cliente_id"] = this.state.filterOptions.id;
      filtersXls["cliente_id"] = this.state.filterOptions.id;
    }

    if (this.state.filterOptions.status_id) {
      if (this.state.filterOptions.status_id !== 7) {
        query["status_id"] = this.state.filterOptions.status_id;
        filtersXls.status_id = this.state.filterOptions.status_id;
      }
    }

    if (this.state.filterOptions.fecha_alta) {
      query["fecha_alta"] = {
        $gte: moment(this.state.filterOptions.fecha_alta).format("YYYY-MM-DD"), // .hour(0).minute(1).second(1).toDate()
      };
      filtersXls.fecha_alta = moment(
        this.state.filterOptions.fecha_alta
      ).format("YYYY-MM-DD");
    }

    if (this.state.filterOptions.fecha_entrega) {
      query["fecha_entrega"] = {
        $lte: moment(this.state.filterOptions.fecha_entrega).format(
          "YYYY-MM-DD"
        ), // .hour(23).minute(58).second(59).toDate()
      };
      filtersXls.fecha_entrega = moment(
        this.state.filterOptions.fecha_entrega
      ).format("YYYY-MM-DD");
    }

    if (this.state.filterOptions.ref_sae) {
      query["ref_sae"] = this.state.filterOptions.ref_sae;
      filtersXls.pedido_id = this.state.filterOptions.ref_sae;
    }

    if (this.state.filterOptions.partida_id) {
      let partida = await Feathers.service("partida").get(
        this.state.filterOptions.partida_id
      );
      filtersXls.partida_id = this.state.filterOptions.partida_id;
      if (partida) {
        query["ref_sae"] = partida.pedido_id;
      }
    }

    console.log("---", filtersXls);

    this.setState({ loading: true }, () => {
      Feathers.service("pedido")
        .find({
          query,
        })
        .then((res) => {
          res = this.handleSort(res);
          
          res.data = res.data.filter((pedido) => pedido.partidas !== null);

          if (!this.state.shouldExport) {
            this.setState({
              orders: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }

          // TODO: fix orders, invoice and providers export
          if (this.state.shouldExport) {
            console.log("---", filtersXls);
            if (res.length > 1000) {
              const numArrays = Math.ceil(res.length / 1000);
              const subArray = res.slice(0, 1000);
              exportToXls(subArray, "admin_orders", filtersXls);
            } else {
              exportToXls(res, "admin_orders", filtersXls);
            }

            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  }

  openInfoModal(row) {
    this.setState({
      isInfoOpened: true,
      currentRow: row.original,
    });
  }

  closeInfoModal() {
    this.setState(
      {
        isInfoOpened: false,
      },
      () => {
        this.fetchData({
          page: this.state.page,
          pageSize: this.state.pageSize,
        });
      }
    );
  }

  onValidateOrder(orderId) {
    this.fetchData({ page: this.state.page, pageSize: this.state.pageSize });

    // Feathers.service("revision")
    //   .find({
    //     query: {
    //       pedido_id: orderId,
    //     },
    //   })
    //   .then((res) => {
    //     Feathers.service("revision")
    //       .patch(res.data[0].id, {
    //         //hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
    //         hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
    //         status_tarea_id: 2,
    //         usuario_id: this.props.user.id,
    //       })
    //       .then((res) => {
    //         //console.log(res, 'todo bien')
    //       })
    //       .catch((err) => {
    //         console.log("error al saltar tarea de revision", err);
    //       });
    //   });
  }

  _handleEditButton = async (order) => {
    this.props.dispatch(push("/order/edit/" + order.id));
  };

  createInvoice = async (order) => {
    const pattern =
      "[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]";
    const rfc = order.cliente.rfc;

    if (!rfc.match(pattern)) {
      swal({
        title: "RFC del cliente inválido",
        text: "Verifique los datos",
        type: "error",
        confirmButtonText: "Aceptar",
        reverseButtons: true,
      });
      return;
    }

    try {
      window.location.href = `http://facturacion.bingham.acsyt.com/api/orders/billing/${order.id}`;
    } catch (error) {
      console.log(error, "averrr");
      swal({
        title: "Ocurrió un error",
        type: "error",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        reverseButtons: true,
      });
    }
  };

  createRemission = async (order) => {
    try {
      window.location.href = `http://facturacion.bingham.acsyt.com/api/orders/remission/${order.id}`;
    } catch (error) {
      swal({
        title: "Ocurrió un error",
        type: "error",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        reverseButtons: true,
      });
    }
  };

  _handleInstaValidate = async (order) => {
    swal({
      title: "¿Está usted seguro?",
      text: "¡Va a validar un pedido!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.setState(
          {
            loading: true,
          },
          async () => {
            Feathers.service("pedido")
              .patch(order.id, {
                status_id: 2,
              })
              .then((res) => {
                this.onValidateOrder(order.id);
              })
              .catch((err) => {
                console.log(err, "error");
                // swal({
                //   title: "El pedido ya fue validado",
                //   text: "El pedido ya fue validado. Recargue la pagina.",
                //   type: "warning",
                //   confirmButtonColor: "#3085d6",
                //   confirmButtonText: "Ok",
                // });
              });
            this.setState({
              loading: false,
            });
          }
        );
      }
    });
  };

  _handleOnDelete = (order) => {
    if (order.status_id === 1) {
      swal({
        title: "¿Está usted seguro?",
        text: "¡Vas a cancelar un pedido!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.setState(
            {
              loading: true,
            },
            async () => {
              await Feathers.service("pedido").patch(order.id, {
                status_id: 6,
              });

              this.onValidateOrder();

              this.setState({
                loading: false,
              });
            }
          );
        }
      });
    } else {
      this.deleteOrder(order);
    }
  };

  deleteOrder = (order) => {
    swal({
      title: "¿Está usted seguro?",
      text: "La orden se va a eliminar.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
    }).then(async (result) => {
      if (result.value) {
        const pedido_id = order.id;

        const partidas_del_pedido = await Feathers.service("partida").find({
          query: {
            $limit: "-1",
            pedido_id: pedido_id,
          },
        });

        const partidas_ids = partidas_del_pedido.map((v) => v.id);

        const unidades_de_la_partida = await Feathers.service("rodillo").find({
          query: {
            $limit: "-1",
            partida_id: {
              $in: partidas_ids,
            },
          },
        });

        const unidades_ids = unidades_de_la_partida.map((v) => v.id);

        await this.setState({
          loading: true,
        });

        // baja de construccion

        try {
          let tarea_contruccion = await Feathers.service("construccion").find({
            query: {
              rodillo_id: {
                $in: unidades_ids,
              },
            },
          });

          if (tarea_contruccion.data.length >= 1) {
            let baja_construccion = await Feathers.service(
              "construccion"
            ).patch(
              null,
              {
                status_tarea_id: 3,
                cancel: 1,
              },
              {
                query: {
                  rodillo_id: {
                    $in: unidades_ids,
                  },
                },
              }
            );
          }
        } catch (error) {
          console.log("error en contruccion");
          console.log(error);
        }

        let tarea_control_calidad = await Feathers.service(
          "control_calidad"
        ).find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_control_calidad.data.length >= 1) {
          let baja_control_calidad = await Feathers.service(
            "control_calidad"
          ).patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_revision = await Feathers.service("revision").find({
          query: {
            pedido_id: pedido_id,
          },
        });

        if (tarea_revision.data.length >= 1) {
          const baja_revision = await Feathers.service("revision").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                pedido_id: pedido_id,
              },
            }
          );
        }

        let tarea_corte = await Feathers.service("corte").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_corte.data.length >= 1) {
          let baja_corte = await Feathers.service("corte").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_desbaste = await Feathers.service("desbaste").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_desbaste.data.length >= 1) {
          let baja_desbaste = await Feathers.service("desbaste").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_desmonte = await Feathers.service("desmonte").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_desmonte.data.length >= 1) {
          let baja_desmonte = await Feathers.service("desmonte").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_desviste = await Feathers.service("desviste").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_desviste.data.length >= 1) {
          let baja_desviste = await Feathers.service("desviste").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        // vamos a handlear lo de la tabla pedido

        let baja_pedido = await Feathers.service("pedido").patch(pedido_id, {
          status_id: 6,
        });

        // seguiremos con la baja de rectificado

        let tarea_rectificado = await Feathers.service("rectificado").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_rectificado.data.length >= 1) {
          let baja_rectificado = await Feathers.service("rectificado").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        // vamos a handlear lo que pasa en la tabla rodillo

        let baja_rodillo = await Feathers.service("rodillo").patch(
          null,
          {
            status_tarea_id: 3,
          },
          {
            query: {
              id: {
                $in: unidades_ids,
              },
            },
          }
        );

        // vamos a handlear lo de los molinos
        // primero tenemos que traer las tareas de molino cuya partida sea parte del pedido y que no estén iniciadas

        const tarea_molino = await Feathers.service("molino").find({
          query: {
            partida_id: {
              $in: partidas_ids,
            },
            status_tarea_id: null,
            $limit: "-1",
          },
        });

        const molino_ids = tarea_molino.map((v) => v.id);

        if (molino_ids.length >= 1) {
          const baja_molino = await Feathers.service("molino").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                id: {
                  $in: molino_ids,
                },
              },
            }
          );
        }

        const baja_vulcanizado = await Feathers.service(
          "pendiente_vulcanizado"
        ).remove(null, {
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        const incidencias_resuletas = await Feathers.service(
          "incidencia"
        ).patch(
          null,
          {
            solucion: "Orden cancelada",
          },
          {
            query: {
              rodillo_id: {
                $in: unidades_ids,
              },
            },
          }
        );

        await this.setState({
          loading: false,
        });

        this.onValidateOrder();
      }
    });
  };

  handleResultSelect = async (e, { result }) => {
    this.setState({ client: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
      page: 0,
    });

    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingSearch: true, client: value });

    setTimeout(() => {
      if (this.state.client.length < 1) return this.resetComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingSearch: false,
            results: res.data,
          });
        });
    }, 500);
  };

  handleCleanButton = async () => {
    await this.setState({
      client: "",
      results: null,
      sae: "",
      filterOptions: {
        ...this.state.filterOptions,
        id: null,
        status_id: null,
        fecha_entrega: "",
        fecha_alta: "",
        ref_sae: null,
        partida_id: null,
      },
    });

    this.fetchData(this.state);
  };

  handleResultSaeSelect = async (e, { result }) => {
    this.setState({ sae: result.ref_sae });

    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        ref_sae: result.ref_sae,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchSaeChange = (e, { value }) => {
    this.setState({ saeLoading: true, sae: value });

    setTimeout(() => {
      if (this.state.sae.length < 1) return this.resetSaeComponent();

      Feathers.service("pedido")
        .find({
          query: {
            ref_sae: { $like: `%${value}%` },
            $limit: 5,
          },
        })
        .then((res) => {
          this.setState({
            saeLoading: false,
            saeResults: res.data,
          });
        });
    }, 500);
  };

  resetSaeComponent = () =>
    this.setState({ saeLoading: false, saeResults: [], sae: "" });

  resetComponent = () =>
    this.setState({ loadingSearch: false, results: [], client: "" });

  //validar si una pedido ya está vencido
  validateDate = (order) => {
    const currentDate = moment().format("YYYY-MM-DD");
    const deliveryDate = moment(order.fecha_entrega).format("YYYY-MM-DD");
    const validate = moment(deliveryDate).isBefore(currentDate);
    if (
      validate &&
      (order.status_id === 1 || order.status_id === 2) &&
      order.is_valid
    ) {
      Feathers.service("notificacion")
        .create({
          mensaje: `La fecha de entrega de la orden ${order.id} ha vencido`,
          perfil_id: 1,
          tipo: 3,
          fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
          adjunto: order.id,
        })
        .then((res) => {
          Feathers.service("pedido").patch(order.id, {
            is_valid: 0,
          });
        })
        .catch((err) => {
          console.log(err, "error");
        });

      return (
        <Popup
          trigger={<Icon circular color="red" name="alarm" />}
          content="La fecha de entrega está fuera del período establecido"
          size="large"
        />
      );
    }
  };

  validateProduct = (data) => {
    if (data.partidas) {
      if (data.partidas.producto) {
        if (data.partidas.producto.id >= 16) {
          return true;
        }
        return false;
      } else {
        data.partidas.forEach((e) => {
          if (e.producto.id >= 16) {
            return true;
          }
        });
        return false;
      }
    }
    this.fetchData();
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>#OT</label>
                  <Input
                    type="number"
                    value={this.state.filterOptions.ref_sae || ""}
                    onChange={(event, data) => {
                      this.setState({
                        filterOptions: {
                          ...this.state.filterOptions,
                          ref_sae: data.value,
                        },
                      });
                      if (data.value.length >= 3) {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              ref_sae: data.value,
                            },
                            page: 0,
                          },
                          () => this.fetchData(this.state)
                        );
                      }
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label># de Partida</label>
                  <Input
                    type="number"
                    value={this.state.filterOptions.partida_id || ""}
                    onChange={(event, data) => {
                      this.setState({
                        filterOptions: {
                          ...this.state.filterOptions,
                          partida_id: data.value,
                        },
                      });
                      if (data.value.length >= 3) {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              partida_id: data.value,
                            },
                            page: 0,
                          },
                          () => this.fetchData(this.state)
                        );
                      }
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Cliente</label>
                  <Search
                    icon=""
                    loading={this.state.loadingSearch}
                    value={this.state.client}
                    results={this.state.results}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    resultRenderer={renderSearchResults}
                    placeholder="Cliente"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Estatus</label>
                  <Form.Dropdown
                    selection
                    options={this.state.filterOptions.status_options}
                    onChange={(e, { value }) => {
                      this.setState(
                        {
                          filterOptions: {
                            ...this.state.filterOptions,
                            status_id: value,
                          },
                        },
                        (_) => this.fetchData(this.state)
                      );
                    }}
                    value={this.state.filterOptions.status_id}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Fecha Alta</label>
                  <DayPickerInput
                    value={this.state.filterOptions.fecha_alta}
                    format="YYYY-MM-DD"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    onDayChange={(date) => {
                      this.setState(
                        {
                          filterOptions: {
                            ...this.state.filterOptions,
                            fecha_alta: date,
                          },
                          page: 0,
                        },
                        (_) => this.fetchData(this.state)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Fecha Entrega</label>
                  <DayPickerInput
                    value={this.state.filterOptions.fecha_entrega}
                    format="YYYY-MM-DD"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    onDayChange={(date) => {
                      this.setState(
                        {
                          filterOptions: {
                            ...this.state.filterOptions,
                            fecha_entrega: date,
                          },
                          page: 0,
                        },
                        (_) => this.fetchData(this.state)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.fetchExportXls();
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Mostrar OT</label>
                  {/* <input type="checkbox" name="showSAE"/> */}
                  <Toggle
                    icons={false}
                    onClick={() => {
                      this.handleSaeToggle();
                    }}
                  ></Toggle>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={
            this.state.showSae
              ? this.state.tableColumns
              : this.state.tableColumns.slice(1)
          }
          manual
          data={this.state.orders}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / 100)}
          onFetchData={this.fetchData}
          // onFetchData={(state, instance) => {
          //   this.fetchData(state);
          //   this.interval = setInterval(() => {
          //     window.location.reload();
          //     //console.log('recargando...')
          //   }, 300000)
          // }}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />
        <OrdersInfoModal
          isOpened={this.state.isInfoOpened}
          order={this.state.currentRow}
          onClose={this.closeInfoModal}
          callback={this.onValidateOrder}
          user={this.props.user.id}
        ></OrdersInfoModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null, null, {
  withRef: true,
})(OrdersList);
