import React, { Component } from "react";
import {
  Tab,
  Form,
  Search,
  Label,
  Dropdown,
  Grid,
  Modal,
  Button,
  Step,
  Segment,
  Confirm,
  Table,
} from "semantic-ui-react";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import PropTypes from "prop-types";
import Feathers from "../../redux/FeathersRedux";
import css from "./OrdersForm.css";
import Currency from "react-currency-formatter";
import PanelOrdersStepper from "./Stepper";
import Notifications, { notify } from "react-notify-toast";
import swal from "sweetalert2";
import moment from "moment";
import { connect } from "react-redux";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

class OrdersForm extends Component {
  constructor() {
    super();

    // this.jwt = new JwtHelper();
    // let userId = this.jwt.decodeToken(window.localStorage.getItem('feathers-jwt')).userId;

    this.state = {
      sendingOrder: false,
      loading: false,
      cliente: "",
      results: [],
      fecha_entrega: null,
      focused: false,
      newModalOpened: false,
      selectedPartida: null,
      pedido: {
        ref_sae: null,
        cliente_id: null,
        vendedor_id: 0,
        fecha_alta: moment().format("YYYY-MM-DD"),
        fecha_entrega: null,
        moneda_id: null,
        email: "",
        email_adicional: "",
        observaciones: "",
        validado: 0,
        flete_id: null,
        precio_flete: null,
        dir_entrega: "",
        col_entrega: "",
        municipio_entrega: "",
        estado_entrega: "",
        pais_entrega: null,
        responsable_entrega: "",
        status_id: 1,
        orden_compra: "",
        numero_salida: "",
      },
      productos: [],
      formulas: [],
      formulasMancheta: [],
      colores: [],
      fletes: [],
      partidas: [],
      tipo_manchetas: [],
    };

    this.updatePedido = this.updatePedido.bind(this);
    this.fetchProducts = this.fetchProducts.bind(this);
    this.fetchFormulas = this.fetchFormulas.bind(this);
    this.fetchColors = this.fetchColors.bind(this);
    this.fetchFreights = this.fetchFreights.bind(this);
    this.parseDropdown = this.parseDropdown.bind(this);
    this.removeProducto = this.removeProducto.bind(this);
    this.openModal = this.openModal.bind(this);
    this.addProducto = this.addProducto.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  updatePedido(key, value) {
    this.setState({
      pedido: { ...this.state.pedido, [key]: value },
    });
  }

  _isOutsideRange = (a) => {
    let date1 = new Date(new Date().getTime() + 2.5 * 24 * 60 * 60 * 1000);
    let date2 = new Date(a._d);
    return date2 < date1;
  };

  handleResultSelect = (e, { result }) => {
    this.setState({ cliente: result.nombre }, () =>
      this.updatePedido("cliente_id", result.id)
    );
    Feathers.service("cliente")
      .find({
        query: {
          id: result.id,
        },
      })
      .then((res) => {
        this.setState({
          pedido: {
            ...this.state.pedido,
            email: res.data[0].email,
            dir_entrega: res.data[0].direccion,
            col_entrega: res.data[0].colonia,
            municipio_entrega: res.data[0].ciudad,
            estado_entrega: res.data[0].estado,
            pais_entrega: res.data[0].pais,
            moneda_id: res.data[0].moneda_id,
          },
        });
      });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, cliente: value });

    setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loading: false,
            results: res.data,
          });
        });
    }, 500);
  };

  resetComponent = () =>
    this.setState({ loading: false, results: [], cliente: "" });

  parseDropdown(array, optionalId) {
    let pews = array.map((pew) => {
      let grr = {
        key: pew.nombre || pew.tipo_flete + " ($" + pew.precio + ")",
        text: pew.nombre || pew.tipo_flete + " ($" + pew.precio + ")",
        value: optionalId ? pew[optionalId] : pew.id,
      };

      if (pew.imagen) grr.image = { avatar: true, src: pew.imagen };
      return grr;
    });
    return pews;
  }

  fetchProducts() {
    Feathers.service("producto")
      .find({
        query: {
          $limit: "-1",
          activo: 1,
        },
      })
      .then((res) => {
        let prods = this.parseDropdown(res);
        this.setState({
          productos: prods,
        });
      });
  }

  fetchTipoManchetas = async () => {
    let tipo_manchetas = await Feathers.service("tipo_mancheta").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    tipo_manchetas = tipo_manchetas.map((v) => {
      return {
        key: v.id,
        value: v.id,
        text: v.nombre,
      };
    });

    await this.setState({
      tipo_manchetas,
    });
  };

  fetchFormulas() {
    Feathers.service("formula")
      .find({
        query: {
          $limit: "-1",
          activo: 1,
        },
      })
      .then((res) => {
        this.setState({
          formulas: res.map((formula) => {
            return {
              key: formula.id,
              text: formula.nombre,
              value: formula,
            };
          }),
        });
      });
  }

  fetchFormulasMancheta = () => {
    Feathers.service("formula")
      .find({
        query: {
          $limit: "-1",
          //activo: 1
        },
      })
      .then((res) => {
        this.setState({
          formulasMancheta: res.map((formula) => {
            return {
              key: formula.id,
              text: formula.nombre,
              value: formula,
            };
          }),
        });
      });
  };

  fetchColors() {
    Feathers.service("color")
      .find()
      .then((res) => {
        let forms = this.parseDropdown(res.data);
        this.setState({
          colores: forms,
        });
      });
  }

  fetchFreights() {
    Feathers.service("flete")
      .find({
        query: {
          activo: 1,
        },
      })
      .then((res) => {
        let freights = this.parseDropdown(res.data);
        this.setState({
          fletes: freights,
        });
      });
  }

  addProducto(prod) {
    let prods = [...this.state.partidas];
    if (this.state.selectedPartida !== null) {
      if (prod.id === this.state.selectedPartida.id) {
        // tenemos que obtener el indice de la partida seleccionada
        let index = prods.findIndex(
          (x) => x.id === this.state.selectedPartida.id
        );
        prods.splice(index, 1);
        // tenemos que elminar el actual y agregar el nuevo
      }
    }
    prods.push(prod);

    this.setState(
      {
        partidas: prods,
      },
      () => this.closeModal()
    );
  }

  removeProducto(index) {
    let prods = [...this.state.partidas];

    prods.splice(index, 1);

    this.setState({
      partidas: prods,
    });
  }

  updateProducto(index) {
    let prods = [...this.state.partidas];

    let productoSeleccionado = prods[index];

    this.setState({
      selectedPartida: productoSeleccionado,
      newModalOpened: true,
    });
  }

  fetchApiDollar = async () => {
    await Feathers.service("configuraciones")
      .find({
        query: {
          nombre: "cambio_dolar",
        },
      })
      .then((res) => {
        const currentDate = moment().format("YYYY-MM-DD");
        const isCurrent = moment(currentDate).isSame(res.data[0].fecha_cambio);
        if (isCurrent) {
          console.log("Ya se hizo una peticion del valor del dolar hoy");
        } else {
          const URl_API =
            "https://openexchangerates.org/api/latest.json?app_id=cc049e4fed0747f79ce473bdf6c3ea83";
          fetch(URl_API)
            .then((response) => response.json())
            .then((data) => {
              Feathers.service("configuraciones").patch(res.data[0].id, {
                valor: data.rates.MXN,
                fecha_cambio: currentDate,
              });
            });
        }
      });

    /*      */
  };

  componentDidMount() {
    this.fetchProducts();
    this.fetchFormulas();
    this.fetchFormulasMancheta();
    this.fetchColors();
    this.fetchFreights();
    this.fetchTipoManchetas();
    this.fetchApiDollar();
  }

  openModal() {
    const { fecha_entrega, moneda_id, cliente_id, ref_sae } = this.state.pedido;

    if (!fecha_entrega || !moneda_id || !cliente_id /* || !ref_sae */)
      return swal(
        "¡Espera!",
        "Todos los datos de entrega son obligatorios para agregar un producto",
        //"La fecha y la moneda son obligatorias para agregar un producto",
        "info"
      );

    this.setState({
      selectedPartida: null,
      newModalOpened: true,
    });
  }

  closeModal() {
    this.setState({
      newModalOpened: false,
    });
  }

  registerOrder = async () => {
    // tenemos que eliminar el formula debido a que ese campo no existe en la base de datos pero de igual manera lo necesitamos para ediciones
    let partidas = this.state.partidas.map((v) => {
      delete v.id;
      delete v.formula;
      delete v.parte_id;
      delete v.tipo_mancheta_id;
      delete v.forma_mancheta_id;
      delete v.perimetro;
      delete v.espesor;
      delete v.altura;
      delete v.dureza;
      delete v.unitario;
      return v;
    });

    let newOrder = {
      pedido: this.state.pedido,
      partidas,
    };

    await this.setState({
      sendingOrder: true,
    });

    delete newOrder.pedido.email;
    newOrder.pedido.fecha_entrega = moment(
      newOrder.pedido.fecha_entrega
    ).format("YYYY-MM-DD");
    newOrder.pedido.vendedor_id = this.props.auth.user.id;
    // assert that partidas is not empty nor null
    if (newOrder.partidas !== null && newOrder.partidas.length) {
      Feathers.service("pedido")
        .create(newOrder)
        .then((res) => {
          notify.show("Pedido registrado", "success");
          // setTabby(0);
          this.setState({
            sendingOrder: false,
            loading: false,
            cliente: "",
            results: [],
            fecha_entrega: null,
            focused: false,
            newModalOpened: false,
            pedido: {
              ref_sae: null,
              cliente_id: null,
              vendedor_id: this.props.auth.user.id,
              fecha_alta: moment().format("YYYY-MM-DD"),
              fecha_entrega: null,
              moneda_id: "MX",
              email_adicional: "",
              email: "",
              observaciones: "",
              validado: 0,
              flete_id: null,
              precio_flete: null,
              dir_entrega: "",
              col_entrega: "",
              municipio_entrega: "",
              estado_entrega: "",
              pais_entrega: "",
              responsable_entrega: "",
              status_id: 1,
              orden_compra: "",
              numero_salida: "",
            },
            partidas: [],
          });
          this.props.onCreate();
        })
        .catch((e) => {
          notify.show("Se produjo un error", "error");
        });
    } else {
      swal({
        type: "error",
        title: "Oops...",
        text: "Esta orden no cuenta con una partida, inténtelo otra vez.",
      });
      this.setState({
        sendingOrder: false,
        loading: false,
        cliente: "",
        results: [],
        fecha_entrega: null,
        focused: false,
        newModalOpened: false,
        pedido: {
          ref_sae: null,
          cliente_id: null,
          vendedor_id: this.props.auth.user.id,
          fecha_alta: moment().format("YYYY-MM-DD"),
          fecha_entrega: null,
          moneda_id: "MX",
          email_adicional: "",
          email: "",
          observaciones: "",
          validado: 0,
          flete_id: null,
          precio_flete: null,
          dir_entrega: "",
          col_entrega: "",
          municipio_entrega: "",
          estado_entrega: "",
          pais_entrega: "",
          responsable_entrega: "",
          status_id: 1,
          orden_compra: "",
          numero_salida: "",
        },
        partidas: [],
      });
    }
  };

  //Esta función duplicaba los elementos que se querían actualizar en el registro de ordenes
  cleanPartidaProps = () => {
    this.setState({
      selectedPartida: null,
    });
  };

  findAndRenderProductName = (partida) => {
    const { producto_id } = partida;
    const { productos } = this.state;

    const found = productos.find((ele) => {
      if (+ele.value === +producto_id) return ele;
    });

    return found ? found.text : "---";
  };

  render() {
    return (
      <Segment raised loading={this.state.sendingOrder}>
        <Form>
          <Grid columns={2}>
            {/* LEFT PANEL */}
            <Grid.Column>
              <Grid.Row>
                <Form.Group>
                  <Form.Field required width={16}>
                    <label>Cliente</label>
                    <Search
                      icon=""
                      loading={this.state.loading}
                      value={this.state.cliente}
                      results={this.state.results}
                      onResultSelect={this.handleResultSelect}
                      onSearchChange={this.handleSearchChange}
                      resultRenderer={renderSearchResults}
                      placeholder="Cliente"
                      className="full-width"
                      required
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Row>
              <Grid.Row>
                <Form.Group>
                  {/* <Form.Field width={5} required>
                    <label>REF de SAE</label>
                    <Form.Input
                      placeholder="SAE"
                      
                      value={this.state.pedido.ref_sae}
                      onChange={(e, d) => {
                        this.setState({
                          pedido: {
                            ...this.state.pedido,
                            ref_sae: d.value
                          }
                        });
                      }}
                    />
                  </Form.Field> */}

                  <Form.Field required width={5}>
                    <label>Fecha Entrega</label>
                    <SingleDatePicker
                      date={this.state.pedido.fecha_entrega}
                      onDateChange={(value) =>
                        this.updatePedido("fecha_entrega", value)
                      }
                      focused={this.state.focused}
                      onFocusChange={({ focused }) =>
                        this.setState({ focused })
                      }
                      numberOfMonths={1}
                      //isOutsideRange={this._isOutsideRange}
                      block={true}
                    />
                  </Form.Field>

                  <Form.Dropdown
                    required
                    width={6}
                    label="Moneda"
                    selection
                    value={this.state.pedido.moneda_id}
                    options={[
                      { key: 1, text: "MXN", value: 1 },
                      { key: 2, text: "USD", value: 2 },
                    ]}
                    placeholder="Moneda"
                    onChange={(e, { value }) =>
                      this.updatePedido("moneda_id", value)
                    }
                  />
                </Form.Group>
              </Grid.Row>
              <Grid.Row>
                <Form.Group>
                  <Form.Input
                    width={8}
                    value={this.state.pedido.orden_compra}
                    label="Orden de compra"
                    name="orden_compra"
                    type="text"
                    placeholder="Orden de compra"
                    onChange={(e, { name, value }) =>
                      this.updatePedido(name, value)
                    }
                  />
                  <Form.Input
                    width={8}
                    value={this.state.pedido.numero_salida}
                    label="Número de salida"
                    name="numero_salida"
                    type="text"
                    placeholder="Número de salida"
                    onChange={(e, { name, value }) =>
                      this.updatePedido(name, value)
                    }
                  />
                </Form.Group>
              </Grid.Row>
              <Grid.Row>
                <Form.Group>
                  <Form.Input
                    width={5}
                    value={this.state.pedido.email}
                    label="Email"
                    name="email"
                    type="text"
                    placeholder="N/A"
                    disabled
                  />
                  <Form.Input
                    width={5}
                    value={this.state.pedido.email_adicional}
                    label="Email Adicional"
                    name="email_adicional"
                    type="text"
                    placeholder="N/A"
                    onChange={(e, { name, value }) =>
                      this.updatePedido(name, value)
                    }
                  />
                  <Form.Input
                    width={5}
                    value={this.state.pedido.observaciones}
                    label="Observaciones"
                    name="observaciones"
                    type="text"
                    placeholder="N/A"
                    onChange={(e, { name, value }) =>
                      this.updatePedido(name, value)
                    }
                  />
                </Form.Group>
              </Grid.Row>
              <Grid.Row>
                <Form.Group>
                  <Form.Input
                    width={8}
                    value={this.state.pedido.dir_entrega}
                    label="Calle"
                    name="dir_entrega"
                    type="text"
                    placeholder="Calle"
                    onChange={(e, { name, value }) =>
                      this.updatePedido(name, value)
                    }
                  />
                  <Form.Input
                    width={8}
                    value={this.state.pedido.col_entrega}
                    label="Colonia"
                    name="col_entrega"
                    type="text"
                    placeholder="Colonia"
                    onChange={(e, { name, value }) =>
                      this.updatePedido(name, value)
                    }
                  />
                </Form.Group>
              </Grid.Row>
              <Grid.Row>
                <Form.Group>
                  <Form.Input
                    width={5}
                    value={this.state.pedido.municipio_entrega}
                    label="Municipio"
                    name="municipio_entrega"
                    type="text"
                    placeholder="Municipio"
                    onChange={(e, { name, value }) =>
                      this.updatePedido(name, value)
                    }
                  />
                  <Form.Input
                    width={5}
                    value={this.state.pedido.estado_entrega}
                    label="Estado"
                    name="estado_entrega"
                    type="text"
                    placeholder="Estado"
                    onChange={(e, { name, value }) =>
                      this.updatePedido(name, value)
                    }
                  />
                  <Form.Input
                    width={6}
                    label="Pais"
                    //selection
                    value={this.state.pedido.pais_entrega}
                    /* options={[
                      { key: 1, text: "México", value: "MX" },
                      { key: 2, text: "Estados Unidos", value: "US" }
                    ]} */
                    type="text"
                    placeholder="Pais"
                    onChange={(e, { value }) =>
                      this.updatePedido("pais_entrega", value)
                    }
                  />
                </Form.Group>
              </Grid.Row>

              <Grid.Row>
                <Form.Group>
                  <Form.Input
                    width={8}
                    value={this.state.pedido.responsable_entrega}
                    label="Responsable"
                    name="responsable_entrega"
                    type="text"
                    placeholder="Responsable"
                    onChange={(e, { name, value }) =>
                      this.updatePedido(name, value)
                    }
                  />
                  <Form.Dropdown
                    width={8}
                    label="Flete"
                    selection
                    value={this.state.pedido.flete_id}
                    options={this.state.fletes}
                    placeholder="Flete"
                    onChange={(e, { value }) =>
                      this.updatePedido("flete_id", value)
                    }
                    upward={true}
                  />
                </Form.Group>
              </Grid.Row>
            </Grid.Column>
            {/* RIGHT PANEL */}
            <Grid.Column>
              <Grid.Row>
                <Button
                  type="button"
                  content="Añadir Producto"
                  icon="add circle"
                  onClick={this.openModal}
                  disabled={this.state.partidas.length === 1}
                />
                {/* Products */}
                <Table singleLine>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Producto</Table.HeaderCell>
                      <Table.HeaderCell>Cantidad</Table.HeaderCell>
                      <Table.HeaderCell>Parte Mancheta</Table.HeaderCell>
                      {/*  <Table.HeaderCell>Formula</Table.HeaderCell>
                      <Table.HeaderCell>Color</Table.HeaderCell> */}
                      <Table.HeaderCell>Peso</Table.HeaderCell>
                      <Table.HeaderCell>Precio</Table.HeaderCell>
                      <Table.HeaderCell>Editar</Table.HeaderCell>
                      <Table.HeaderCell>Remover</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.partidas.map((partida, index) => {
                      let productos = JSON.parse(
                        JSON.stringify(this.state.productos)
                      );

                      let producto_seleccionado = productos.filter((v) => {
                        if (v.value === partida.producto_id) return v;
                      });
                      return (
                        <Table.Row key={index}>
                          {/* <Table.Cell>{this.findAndRenderProductName(partida)}</Table.Cell> */}
                          <Table.Cell>
                            {producto_seleccionado[0].text}
                          </Table.Cell>
                          <Table.Cell>
                            {partida.cantidad ? partida.cantidad : "---"}
                          </Table.Cell>
                          <Table.Cell>
                            {partida.parte_id ? partida.parte_id : "---"}
                          </Table.Cell>
                          {/* <Table.Cell>
                            {partida.formula ? partida.formula.nombre : "---"}
                          </Table.Cell>
                          <Table.Cell>
                            {partida.color_id
                              ? this.state.colores[partida.color_id - 1].text
                              : "---"}
                          </Table.Cell> */}
                          <Table.Cell>{partida.kilos} kg.</Table.Cell>
                          <Table.Cell>
                            <Currency
                              quantity={partida.precio}
                              currency="USD"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              icon="edit"
                              compact
                              size="mini"
                              positive
                              onClick={() => this.updateProducto(index)}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              icon="close"
                              compact
                              size="mini"
                              negative
                              onClick={() => this.removeProducto(index)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Grid.Row>
            </Grid.Column>
          </Grid>
          <Grid.Row>
            <Grid.Column floated="right" width={5}>
              <Button
                onClick={this.registerOrder}
                positive
                floated="right"
                labelPosition="left"
                icon="send"
                label="CREAR ORDEN"
                disabled={this.state.partidas.length < 1}
              />
            </Grid.Column>
          </Grid.Row>
        </Form>

        <Modal
          open={this.state.newModalOpened}
          //closeOnDimmerClick={closeOnDimmerClick}
          //closeIcon
          //onClose={this.closeModal}
        >
          <Modal.Header>
            Añadir Producto
            <Button
              circular
              size="mini"
              basic
              icon="close"
              floated="right"
              onClick={this.closeModal}
            ></Button>
          </Modal.Header>

          <Modal.Content>
            <PanelOrdersStepper
              {...this.props}
              pedido={this.state.pedido}
              productos={this.state.productos}
              //formulas={this.state.formulas}
              formulasMancheta={this.state.formulasMancheta}
              colores={this.state.colores}
              tipo_manchetas={this.state.tipo_manchetas}
              addProducto={this.addProducto}
              closeModal={this.closeModal}
              preloadedPartida={this.state.selectedPartida}
              partidas={this.state.partidas}
              //cleanPartidaProps={this.cleanPartidaProps}
            />
          </Modal.Content>
        </Modal>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersForm);
