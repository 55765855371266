import React, {Component} from 'react';
import Feathers from '../../redux/FeathersRedux';
import {Modal, Grid, Icon, Button, Form} from 'semantic-ui-react';
import swal from 'sweetalert2';


const tipos_notificaciones = [
  {
    value: 1,
    text: 'Alerta Inventario',
    key: 1,
  },
  {
    value: 2,
    text: 'Incidencia',
    key: 2
  }
];

class NotificationForm extends Component {

  state = {
    loading_add_button: false,
    dropdown_perfiles: [],
    toAdd : {
      tipo_notificacion: null,
      perfil_id: null
    }
  };


  async componentDidMount(){

    this.fetchPerfiles()

  }


  fetchPerfiles = async _ => {
    let perfiles = await  Feathers.service('perfil').find({
      query : {
        doPagination: false
      }
    });



    let dropdown_perfiles = perfiles.map( v => {
      return {
        key: v.id,
        text: v.nombre,
        value: v.id
      }
    });

    this.setState({
      dropdown_perfiles
    });

  };


  _add = async _ => {
    if(!this.state.toAdd.perfil_id || !this.state.toAdd.tipo_notificacion) return swal({
      type: 'error',
      title: 'Oops...',
      text: '¡No has llenado todos los campos!',
    });


    /*
    * Vamos a comprobar primero que el permiso no exista
    * si existe le diremos al usuario que ya existe y que no hace falta agregarlo
    * */

    let validacion = await Feathers.service('notificacion_tipo_perfil').find({
      query: {
        ...this.state.toAdd
      }
    });

    if(validacion.total > 0){
      return swal('Esa configuración ya existe; no hace falta agregarla de nuevo.')
    }

    let configuracion_agregada = await Feathers.service('notificacion_tipo_perfil').create(this.state.toAdd);

    if(configuracion_agregada){
      swal(
        '¡Buen trabajo!',
        '¡Se ha agregado la configuración!',
        'success'
      );

      await this.props.onCreate()

      this.setState({
        toAdd: {
          ...this.state.toAdd,
          tipo_notificacion: null,
          perfil_id: null,
        }
      })

    }
  };

  render() {
    return (
      <div>
        <Form size={'large'}>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Dropdown selection options={tipos_notificaciones} label={"Tipo Notificación"} onChange={(e, d) => {
                this.setState({
                  toAdd: {
                    ...this.state.toAdd,
                    tipo_notificacion: d.value
                  }
                })
              }} value={this.state.toAdd.tipo_notificacion} />
            </Form.Field>
            <Form.Field>
              <Form.Dropdown selection options={this.state.dropdown_perfiles} label={"Perfil"} onChange={(e, d) => {
                this.setState({
                  toAdd: {
                    ...this.state.toAdd,
                    perfil_id: d.value
                  }
                })
              }}  value={this.state.toAdd.perfil_id} />
            </Form.Field>
            <Form.Field>
              <label>Añadir</label>
              <Button icon={"plus"} color={"green"} loading={this.state.loading_add_button} onClick={this._add} />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default NotificationForm;