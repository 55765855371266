import React, { Component } from "react";
import { Form, Grid, Button, Image, Label, Icon,Dropdown } from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import Notifications, { notify } from "react-notify-toast";
import swal from "sweetalert2";
import { Dropzone } from "../Dropzone";

export default class CatalogEquipmentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maquinaria: {
        nombre: "",
        descripcion: "",
        fecha_compra: "",
        tipo_maquina_id: null,
      },
      tipos_maquinaria_options: [],
      image: null,
      perfiles: [],
      //perfil: '',
      loading: false,
      options: [],
    };
  }


  componentDidMount = () => {
    this.fetchData();
    this.fetchTiposMaquinarias();
    
  };


  fetchData = () => {
    let options;
    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("perfil")
          .find({
            query: {
              activo: 1,
              doPagination: false
            }
          })
          .then(res => {
            options = res.map((v, i) => {
              return {
                key: i,
                text: v.nombre,
                value: v.id
              };
            });

            this.setState({
              options
            });
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    );
  };

  fetchTiposMaquinarias = async () => {
    let tipo_maquinaria = await Feathers.service("tipo_maquinaria").find({
      query: {
        $limit: "-1",
        activo: 1
      }
    });

    let tipo_maquinaria_options = tipo_maquinaria.map(t => {
      return {
        key: t.id,
        text: t.nombre,
        value: t.id
      };
    });

    this.setState({
      tipo_maquinaria_options
    });
  };

  


  onImageDrop(files, rejectedFiles) {
    this.setState({ files });
  }

  onDropChange = image => {
    this.setState({ image });
  };

  renderDropContent() {
    if (!this.state.files.length)
      return <p>Drop an image or click to select a file to upload.</p>;

    return (
      <Image
        src={this.state.files[0].preview}
        style={{ height: 130, width: "auto" }}
      />
    );
  }

  handleSubmit = () => {

    if (
      !this.state.maquinaria.nombre ||
      !this.state.maquinaria.descripcion ||
      !this.state.maquinaria.tipo_maquina_id ||
      !this.state.maquinaria.fecha_compra ||
      !this.state.image ||
      this.state.perfiles.length < 1
    ) {
      swal({
        title: "Faltan datos por registrar.",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true
      });
      return;
    }

    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("maquinaria")
          .create({
            nombre: this.state.maquinaria.nombre,
            descripcion: this.state.maquinaria.descripcion,
            fecha_compra: this.state.maquinaria.fecha_compra,
            image: this.state.image.base64,
            tipo_maquina_id: this.state.maquinaria.tipo_maquina_id,
            perfiles: this.state.perfiles
          })
          .then(res => {
            this.props.onCreate && this.props.onCreate();
            swal({
              title: "Registro éxitoso",
              type: "success",
              confirmButtonText: "ok",
              reverseButtons: true
            }).then(res => {
              this.setState({
                loading: false,
                maquinaria: {
                  nombre: "",
                  descripcion: "",
                  fecha_compra: "",
                  tipo_maquina_id: null
                },
                perfiles: [],
                image: null
              });
            })
          })
          .catch(err => {
            swal({
              title: "Se produjo un error, intente de nuevo.",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true
            }).then(res => {
              this.setState({ loading: false });
            })
          });
      }
    );
  };

  //------------------------------------------------------------------------------------

  render() {
    return (
      <div>
        <Form onSubmit={this.handleSubmit} size={"large"}>
          <Grid>
            <Grid.Row columns={2} divided>
              <Grid.Column>
                <Form.Input
                  label="Nombre"
                  name="nombre"
                  value={this.state.maquinaria.nombre}
                  type="text"
                  placeholder="Nombre maquinaria"
                  onChange={(e, { name, value }) =>
                    this.setState({
                      maquinaria: { ...this.state.maquinaria, [name]: value }
                    })
                  }
                />
                <Form.TextArea
                  label="Descripción"
                  placeholder="Descripción de este equipo"
                  name="descripcion"
                  value={this.state.maquinaria.descripcion}
                  type="text"
                  onChange={(e, { name, value }) =>
                    this.setState({
                      maquinaria: { ...this.state.maquinaria, [name]: value }
                    })
                  }
                />
                <Form.Input
                  label="Fecha de Compra"
                  name="fecha_compra"
                  value={this.state.maquinaria.fecha_compra}
                  type="date"
                  onChange={(e, { name, value }) =>
                    this.setState({
                      maquinaria: { ...this.state.maquinaria, [name]: value }
                    })
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Dropdown
                   required
                   label="Tipo de maquinaria"
                   placeholder="Seleccione una Opción"
                   options={this.state.tipo_maquinaria_options}
                   selection
                   value={this.state.maquinaria.tipo_maquina_id}
                   onChange={(e, d) => {
                     this.setState({
                       maquinaria: {
                         ...this.state.maquinaria,
                         tipo_maquina_id: d.value
                       }
                     });
                   }}
                />

                <Form.Input label="Perfiles a cargo">
                  <Dropdown
                    placeholder="Selecccione las opciones ncesarias"
                    fluid
                    multiple
                    selection
                    options={this.state.options}
                    value={this.state.perfiles}
                    onChange={(e, { value }) =>
                      this.setState({
                        error: false,
                        perfiles: [...value],
                      })
                    }
                  />
                </Form.Input>
                <Form.Field required>
                  <label>Imagen</label>
                  <Dropzone
                    onChange={this.onDropChange}
                    image={this.state.image}
                    required
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  type="submit"
                  floated="right"
                  positive
                  labelPosition="left"
                  icon="send"
                  content="Registrar"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}
