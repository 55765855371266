import React, { Component } from 'react';
import { Header, Segment, Grid, Icon, Form, Button, Divider, Table} from 'semantic-ui-react'
import { TaskOrderSegment, TaskStatusSegment, TaskItemSegment} from '../'
import Timer from '../../Timer'
import Feathers from '../../../redux/FeathersRedux';
import swal from 'sweetalert2'
import Incidence from '../../Incidence/Incidence'


const PHASE = 4;

export default class ConstructionTask extends Component {

    constructor(props) {
        super(props)

        this.state = {
            lote: "",
            esMancheta: 0,
            producto_id: this.props.task.rodillo.partida.producto_id,
            wrappers: [],
            venda_id: null,
            de_final: null,
            dn_final: null,
            loading_register_button: false,
            rawMaterials: [],
            loadingWeight: false,
            formWeights: true,
            materialsInventory: [],
            sumPartes: 0,
        }

        this.getWrappers = this.getWrappers.bind(this)
        this.renderForm = this.renderForm.bind(this)
        this.saveInfo = this.saveInfo.bind(this)
        this.finishTask = this.finishTask.bind(this)
    }

   
    getRawMaterials = async () => {
      let item = this.props.task.rodillo.partida;
      try {
        let res = await Feathers.service("formula").find({
          query: {
            dureza_id: item.dureza_id,
            material_id: item.material_id,
          },
        });
  
        let formula = res.data[0];
  
        if (formula) {
          let rawMaterials = await Feathers.service("formula_materia_prima").find(
            {
              query: { formula_id: formula.id },
            }
          );
          //console.log(rawMaterials, 'a ver qué hay');
          const partes = rawMaterials.map(i => +i.partes);
          const reducer = (accumulator, currentValue) => accumulator + currentValue;
          const sum = partes.reduce(reducer).toFixed(2);
          console.log(sum, 'sum materiales')
          this.setState({ rawMaterials, sumPartes: sum});

          const idMaterials = rawMaterials.map(m => m.materiaPrima.id);

          await Feathers.service("inventario").find({
            query: {
              clave_id: {
                $in: idMaterials
              }
            }
          }).then((res) => {
            this.setState({
              materialsInventory: res.data
            })
          });

          
        }
      } catch (e) {}
    }

    calculateKilos = (part) => {
      return (((this.props.task.rodillo.partida.kilos/this.props.task.rodillo.partida.cantidad)/this.state.sumPartes) * part).toFixed(3); 
      //return ((part / 230.7) * this.props.task.rodillo.partida.kilos).toFixed(3);
    }

    //TODO hacer que se valide de inmediato o ver de que forma validad bien los pesos de etrada con los pesos de iventario
    updateDetail = (index, value, id) => {
      let rawMaterials = [...this.state.rawMaterials];

      const arrayInventory = this.state.materialsInventory;
      const inputInventory = arrayInventory.find(m => m.clave_id === id);
      
      if(inputInventory) {
        rawMaterials[index].cantidad = inputInventory.cantidad
        rawMaterials[index].peso = value;
      }else {
        rawMaterials[index].cantidad = 0
        rawMaterials[index].peso = value;
      }

      this.setState({
        rawMaterials,
      });

      //console.log(this.state.rawMaterials, 'test');
    }

    checkDisabled() {
      return this.state.rawMaterials.some((r) => !r.peso || r.peso == 0);
    }

    saveWeights = () => {
      swal({
        title: "¿Registrar pesos?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((res) => {
        if (res.value) {
          this.setState(
            {
              loadingWeight: true,
            },
            (_) => {

              for(let value of this.state.rawMaterials) {
                if(+value.peso > +value.cantidad) {
                  console.log(value, 'nopasa')
                  return (swal({
                    type: 'info',
                    title: 'Error en los kilos introducidos',
                    text: `¡No hay suficiente inventario de ${value.materiaPrima.nombre}`,
                  }).then((res) => {
                    this.setState({
                      loadingWeight: false,
                    })
                  }))
                }
              }

              Feathers.service("detalle_construccion_poliuretano")
                .create(
                  this.state.rawMaterials.map((detail) => {
                    return {
                      construccion_poliuretano_id: this.props.task.id,
                      materia_prima_id: detail.materia_prima_id,
                      peso: detail.peso,
                    };
                  })
                )
                .then((res) => {
                  this.setState({
                    loadingWeight: false,
                    formWeights: false,
                  });
                });
            }
          );
        }
      });
    }

    localInitTask = async _ => {
      //ESTA VALIDACIÓN DE INVENTARIO COMPUESTO YA NO SE TOMARÁ EN CUENTA PORQUE ASÍ LO HA PEDIDO RICARDO.
      //LA VALIDACIÓN SE ÚNICAMENTE SE HARÁ PARA EL PESAJA DE LAS MATERIAS QUE HAY POR FORMULA. 
        /* if([3].indexOf(this.state.producto_id) !== -1){
            
            let laFormula = await Feathers.service('formula').find({
                query: {
                    $limit: '-1',
                    dureza_id: this.props.task.rodillo.partida.dureza_id,
                    material_id: this.props.task.rodillo.partida.material_id
                },
            });

          

            let inventario_compuesto = await Feathers.service('inventario_compuesto').find({
                query: {
                    formula_id: laFormula[0].id,
                    color_id: this.props.task.rodillo.partida.color_id,
                    $limit: '-1'
                },
            });

        

            let cantidad = (inventario_compuesto[0] ? inventario_compuesto[0].cantidad : 0) - (this.props.task.rodillo.partida.kilos / this.props.task.rodillo.partida.cantidad)

       

            if(+(cantidad) >= 0){
                this.props.initTask(this.props.task);
            }else{
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: '¡No tienes inventario compuesTo! No puedes iniciarla',
                  })

            }
            return 0
        }

        if([4].indexOf(this.state.producto_id) !== -1){
            let laFormula = await Feathers.service('formula').find({
                query: {
                    $limit: '-1',
                    dureza_id: this.props.task.rodillo.partida.dureza_id,
                    material_id: this.props.task.rodillo.partida.material_id
                },
            });

            let inventario_compuesto = await Feathers.service('inventario_compuesto').find({
                query: {
                    formula_id: laFormula[0].id,
                    color_id: this.props.task.rodillo.partida.color_id,
                    $limit: '-1'
                },
            });


            let cantidad = (inventario_compuesto[0] ? inventario_compuesto[0].cantidad : 0)  - (this.props.task.rodillo.partida.kilos / this.props.task.rodillo.partida.cantidad)


            if(+(cantidad) >= 0){
                this.props.initTask(this.props.task)
                
            }else{
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: '¡No tienes inventario compuesto! No puedes iniciarla',
                  })
            }

            return 0
        } */


        this.props.initTask(this.props.task);
        
    }

    renderInitButton = () => {
        if (this.props.task.hora_inicio) return null;
        return (
            <div style={{marginTop: 20}}>
                <Button onClick={() => this.localInitTask() }  icon='play' fluid color="green" content='Iniciar' />
             </div>
        )
    };


    async componentDidMount() {
        if (!this.props.task.venda_id) {
            await this.getWrappers();
        }
        this.getRawMaterials();
        //console.log(this.props.task.rodillo.partida, 'dame algo')
        // await this.checkIfMancheta()

    }


    checkIfMancheta = async () => {
        await this.setState({
            esMancheta: this.props.task.rodillo.esMancheta
        })
    };

    async finishTask() {
        if([1, 4].indexOf(this.state.producto_id) !== -1){
            // Comentamos la venda porque por el momento no será tomada en cuenta
            if ( /*!this.props.task.venda_id ||   */!this.props.task.de_final || !this.props.task.dn_final) return swal({
                title: 'Atención',
                text: 'Primero debes capturar la información pendiente para finalizar la tarea',
                type: 'info',
            })
        }



        if([5].indexOf(this.state.producto_id) !== -1){
            // Comentamos la venda porque por el momento no será tomada en cuenta
            if ( !this.props.task.venda_id ||  !this.props.task.lote) return swal({
                title: 'Atención',
                text: 'Primero debes capturar la información pendiente para finalizar la tarea',
                type: 'info',
            })
        }

        

        // if(this.state.esMancheta === 1){
        //     if(!this.props.task.altura || !this.props.task.espesor)  return swal({
        //         title: 'Atención',
        //         text: 'Primero debes capturar la información pendiente para finalizar la tarea',
        //         type: 'info',
        //     })
        // }

        this.props.finishTask && this.props.finishTask();

        
    }

    getWrappers() {
        Feathers.service('venda').find()
            .then(res => {
                this.setState({
                    wrappers: res.data.map(w => {
                        return {
                            text: w.nombre,
                            value: w.id
                        }
                    })
                })
            })
    }


    renderForm() {

        if([4, 3].indexOf(this.state.producto_id) !== -1) {
          if (this.props.task.hora_inicio == null ) return null;
          if(this.state.formWeights) {
            console.log(this.state.rawMaterials, 'rawmateriales')
            return (
              <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
                <Header
                  as="h4"
                  attached="top"
                  inverted
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    backgroundColor: "#212121",
                  }}
                >
                  <Icon name="settings" />
                  <Header.Content>Registro de pesos</Header.Content>
                </Header>
                <Segment attached>
                  <Table singleLine compact={true}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Materia prima</Table.HeaderCell>
                        <Table.HeaderCell>Partes</Table.HeaderCell>
                        <Table.HeaderCell>Kilos a pesar</Table.HeaderCell>
                        <Table.HeaderCell>Kilos pesados</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.rawMaterials.map((rm, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>{rm.materiaPrima.nombre}</Table.Cell>
                            <Table.Cell>{rm.partes}</Table.Cell>
                            <Table.Cell>{this.calculateKilos(rm.partes)}</Table.Cell>
                            <Table.Cell>
                              <Form.Input
                                type="number"
                                onChange={(e, { name, value }) =>
                                  this.updateDetail(index, value, rm.materiaPrima.id)
                                }
                                
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
        
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={this.saveWeights}
                      loading={this.state.loadingWeight}
                      disabled={this.checkDisabled()}
                      icon="check"
                      positive
                      content="REGISTRAR PESOS"
                    />
                  </div>
                </Segment>
              </div>
            );
          } else {
            if (!this.props.task.venda_id ) {
              return (
                <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                  <Segment>
                    <Form>
                      <Grid>
                        <Grid.Row>
                          {/* <Grid.Column computer={4} tablet={4} mobile={16}>
                            <Form.Dropdown name="venda_id" onChange={(e, {name, value}) =>  this.setState({ [name]: value })} fluid selection options={this.state.wrappers} value={this.state.venda_id} label='Venda' placeholder='Venda'/>
                          </Grid.Column> */}
                          <Grid.Column computer={4} tablet={5} mobile={16}>
                            <Form.Input name="dn_final" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DN final' placeholder='Diámetro de nucleo final'/>
                          </Grid.Column>
                          <Grid.Column computer={4} tablet={5} mobile={16}>
                            <Form.Input name="de_final" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DE final' placeholder='Diámetro exterior final'/>
                          </Grid.Column>
                          <Grid.Column computer={4} tablet={5} mobile={16}>
                            <Form.Input name="lote" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="text" label='Lote' placeholder='Lote'/>
                          </Grid.Column>
                          <Grid.Column computer={3} tablet={6} mobile={16}>
                            <Button type="button" onClick={this.saveInfo} loading={this.state.loading_register_button} disabled={!this.state.lote || !this.state.de_final || !this.state.dn_final} fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR' />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                  </Segment>
                </div>
  
              )
            }
  
            return (
              <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      {/* <Grid.Column computer={4} tablet={6} mobile={16}>
                        <span style={{display: 'block', fontWeight: 'bold'}}>Venda</span>
                        <span>{this.props.task.venda.nombre}</span>
                      </Grid.Column> */}
                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <span style={{display: 'block', fontWeight: 'bold'}}>DN Final</span>
                        <span>{this.props.task.dn_final}</span>
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <span style={{display: 'block', fontWeight: 'bold'}}>DE Final</span>
                        <span>{this.props.task.de_final}</span>
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <span style={{display: 'block', fontWeight: 'bold'}}>Lote</span>
                        <span>{this.props.task.lote}</span>
                      </Grid.Column>
                    </Grid.Row>
  
                  </Grid>
                </Segment>
              </div>
            )
          }
          
        }

        if([1].indexOf(this.state.producto_id) !== -1){
          if (this.props.task.hora_inicio == null ) return null; 
          if (!this.props.task.venda_id ) {
            return (
              <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Segment>
                  <Form>
                    <Grid>
                      <Grid.Row>
                        {/* <Grid.Column computer={4} tablet={4} mobile={16}>
                          <Form.Dropdown name="venda_id" onChange={(e, {name, value}) =>  this.setState({ [name]: value })} fluid selection options={this.state.wrappers} value={this.state.venda_id} label='Venda' placeholder='Venda'/>
                        </Grid.Column> */}
                        <Grid.Column computer={4} tablet={5} mobile={16}>
                          <Form.Input name="dn_final" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DN final' placeholder='Diámetro de nucleo final'/>
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={5} mobile={16}>
                          <Form.Input name="de_final" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DE final' placeholder='Diámetro exterior final'/>
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={5} mobile={16}>
                          <Form.Input name="lote" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="text" label='Lote' placeholder='Lote'/>
                        </Grid.Column>
                        <Grid.Column computer={3} tablet={6} mobile={16}>
                          <Button type="button" onClick={this.saveInfo} loading={this.state.loading_register_button} disabled={!this.state.lote || !this.state.de_final || !this.state.dn_final} fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR' />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Segment>
              </div>

            )
          }

          return (
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
              <Segment>
                <Grid>
                  <Grid.Row>
                    {/* <Grid.Column computer={4} tablet={6} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>Venda</span>
                      <span>{this.props.task.venda.nombre}</span>
                    </Grid.Column> */}
                    <Grid.Column computer={4} tablet={5} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>DN Final</span>
                      <span>{this.props.task.dn_final}</span>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={5} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>DE Final</span>
                      <span>{this.props.task.de_final}</span>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={5} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>Lote</span>
                      <span>{this.props.task.lote}</span>
                    </Grid.Column>
                  </Grid.Row>

                </Grid>
              </Segment>
            </div>
          )
        }


        if([5].indexOf(this.state.producto_id) !== -1){
            if (this.props.task.hora_inicio == null ) return null;
            if (!this.props.task.venda_id) {
              return (
                <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                  <Segment>
                    <Form>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column computer={4} tablet={4} mobile={16}>
                            <Form.Dropdown name="venda_id" onChange={(e, {name, value}) =>  this.setState({ [name]: value })} fluid selection options={this.state.wrappers} value={this.state.venda_id} label='Venda' placeholder='Venda'/>
                          </Grid.Column>
                          <Grid.Column computer={4} tablet={5} mobile={16}>
                            <Form.Input name="lote" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="text" label='Lote' placeholder='Lote'/>
                          </Grid.Column>
                          <Grid.Column computer={3} tablet={6} mobile={16}>
                            <Button type="button" onClick={this.saveInfo} loading={this.state.loading_register_button} disabled={!this.state.lote} fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR' />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                  </Segment>
                </div>
  
              )
            }
  
            return (
              <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                    <Grid.Column computer={4} tablet={5} mobile={16}>
                        <span style={{display: 'block', fontWeight: 'bold'}}>Venda</span>
                        <span>{this.props.task.venda_id}</span>
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <span style={{display: 'block', fontWeight: 'bold'}}>Lote</span>
                        <span>{this.props.task.lote}</span>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </div>
            )
          }

        return null
    }

    async saveInfo() {

        const dn_esperado = +(this.props.task.rodillo.partida.dn)
        const de_esperado = +(this.props.task.rodillo.partida.de)


        let holgura_dn = await Feathers.service('configuraciones').find({
            query: {
                nombre: 'holgura_dn'
            }
        })

        holgura_dn = +(holgura_dn.data[0].valor)


        let holgura_de = await Feathers.service('configuraciones').find({
            query: {
                nombre: 'holgura_de'
            }
        })

        holgura_de = +(holgura_de.data[0].valor)


        swal({
            title: '¿Registar información?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            reverseButtons: true
        })
            .then(async res => {
                if (!res.value) {
                    return
                };

                const { venda_id, dn_final, de_final, lote } = this.state;


                if([1, 4].indexOf(this.state.producto_id) !== -1){

                    if(dn_final < (dn_esperado - holgura_dn) || dn_final >  dn_esperado ){

                        await swal({
                            type: 'error',
                            title: 'Oops...',
                            text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
                        })
                        await this.incidence.getWrappedInstance().openFromOutside()
                        return
                    }

                    if(de_final < (de_esperado - holgura_de) /*|| de_final >  de_esperado */){
                      // esto lo comentamos porque el dijo que queria que se pudiera poner mas de 
                        await swal({
                            type: 'error',
                            title: 'Oops...',
                            text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
                        })
                        await this.incidence.getWrappedInstance().openFromOutside()
                        return
                    }
                }

               
                Feathers.service('construccion').patch(this.props.task.id, {
                    venda_id,
                    dn_final,
                    de_final,
                    lote
                })
                    .then(async res => {
                        await this.props.updateTask && await this.props.updateTask();
                        await this.props.finishTask && await this.props.finishTask();
                        
                    })

                
            })
    }

    render() {
        return (
            <Segment loading={this.props.loading === true}>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                    <Header as='h2'>
                    <Button onClick={this.props.goBack} icon="arrow left" style={{backgroundColor: 'transparent', color: '#000000de'}}/>
                    
                    Tarea de Construcción
                    </Header>    
                    </Grid.Column>
                    <Grid.Column>
                        <Incidence ref={ref => this.incidence = ref} phase={PHASE} task={this.props.task} cancelTaskByIncidence={this.props.cancelTaskByIncidence} rodillo={this.props.task.rodillo_id} pedido={this.props.task.rodillo.partida.pedido_id}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />  

                <TaskStatusSegment task={this.props.task} finishTask={this.finishTask}></TaskStatusSegment>
                <TaskOrderSegment order={this.props.task.rodillo.partida.pedido} kilos={this.props.task.rodillo.partida.kilos}/>

                <Header as='h3' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px',backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='list' />
                    <Header.Content>Información de la partida</Header.Content>
                </Header>
                <TaskItemSegment roller={this.props.task.rodillo.id} item={this.props.task.rodillo.partida} />
                
                <this.renderForm />

                
                {this.renderInitButton()}

            </Segment>
        )
    }
}
