import React, { Component } from "react";
import {
  Button,
  Sidebar,
  Menu,
  Icon,
  Image,
  Segment,
  Header,
  Responsive,
  Dropdown,
  Accordion,
  Form,
  Portal,
  Checkbox,
  Transition,
} from "semantic-ui-react";
import swal from "sweetalert2";
import { Route, Switch } from "react-router-dom";
import { links, routes } from "../routes/AppLayout";
import { connect } from "react-redux";
import Feathers, {
  feathersAuthentication,
  services,
} from "../redux/FeathersRedux";
import moment from "moment";
class MainContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      minVisible: false,
      activeIndex: [],
      openPortal: false,
      openIncidencesPortal: false,
      notificacionesOn: [],
      notificacionesIncidendesOn: [],
      noNotificationes: 0,

      notifyStyle: "none",
      notificationsToDelete: [],
      notifyIncidenceStyle: "none",
      noIncidencesNotification: 0,

      openOrdersPortal: false,
      notificationOrders: [],
      notificationsOrdersToDelete: [],
      numNotificationOrders: 0,
      notifyOrderStyle: "none",

      openTasksPortal: false,
      notificationTasks: [],
      notificationsTasksToDelete: [],
      numNotificationTasks: 0,
      notifyTasksStyle: "none",
    };

    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.renderLinks = this.renderLinks.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleAccordionClick = this.handleAccordionClick.bind(this);
    this.removeNotifications = this.removeNotifications.bind(this);
  }

  fetchOrders = async () => {
    const currentDate = moment().format("YYYY-MM-DD");
    await Feathers.service("pedido")
      .find({
        query: {
          status_id: {
            $in: [1, 2],
          },
          fecha_entrega: {
            $lt: currentDate,
          },
          $limit: "-1",
          is_valid: 1,
        },
      })
      .then((res) => {
        if (res.length > 0) {
          res.forEach((e) => {
            Feathers.service("pedido").patch(e.id, {
              is_valid: 0,
            });
          });
        }
      });
  };

  fetchIncidencesNotifications = () => {
    Feathers.service("notificacion")
      .find({
        query: {
          status: 1,
          tipo: 2,
          $limit: "-1",
        },
      })
      .then(async (res) => {
        let user_profiles = this.props.auth.user.profiles;

        let perfiles_validos_en_incidencia = await Feathers.service(
          "notificacion_tipo_perfil"
        ).find({
          query: {
            $limit: "-1",
            tipo_notificacion: 2,
          },
        });

        let perfiles_validos = perfiles_validos_en_incidencia.map(
          (v) => v.perfil_id
        );

        /*  let resdata = res.filter((r) => {
          let encontrado = perfiles_validos.some((p) => {
            return user_profiles.indexOf(+p) >= 0;
          });
          if (encontrado) return r;
        }); */

        if (res.length > 0) {
          this.setState({
            noIncidencesNotification: res.length,
            notifyIncidenceStyle: "1px solid white",
          });
        } else {
          this.setState({
            noIncidencesNotification: 0,
            notifyIncidenceStyle: "none",
          });
        }

        this.setState({
          notificacionesIncidendesOn: res,
        });
      });
  };

  fetchNotificationsTasks = () => {
    Feathers.service("notificacion")
      .find({
        query: {
          status: 1,
          tipo: 4,
          $limit: "-1",
        },
      })
      .then((res) => {
        this.setState({
          notificationTasks: res,
          numNotificationTasks: res.length,
          notifyTasksStyle: "1px solid white",
        });
      });
  };

  fetchNotifications = () => {
    Feathers.service("notificacion")
      .find({
        query: {
          status: 1,
          tipo: 1,
          $limit: "-1",
        },
      })
      .then(async (res) => {
        let user_profiles = this.props.auth.user.profiles;

        let perfiles_validos_en_incidencia = await Feathers.service(
          "notificacion_tipo_perfil"
        ).find({
          query: {
            $limit: "-1",
            tipo_notificacion: 1,
          },
        });

        let perfiles_validos = perfiles_validos_en_incidencia.map(
          (v) => v.perfil_id
        );

        /* let resdata = res.filter((r) => {
          let encontrado = perfiles_validos.some((p) => {
            return user_profiles.indexOf(+p) >= 0;
          });
          if (encontrado) return r;
        }); */

        if (res.length > 0) {
          this.setState({
            noNotificationes: res.length,
            notifyStyle: "1px solid white",
          });
        } else {
          this.setState({
            noNotificationes: 0,
            notifyStyle: "none",
          });
        }
        this.setState({
          notificacionesOn: res,
        });
      });
  };

  fetchNotificationsOrders = async () => {
    Feathers.service("notificacion")
      .find({
        query: {
          status: 1,
          tipo: 3,
          $limit: "-1",
        },
      })
      .then((res) => {
        this.setState({
          notificationOrders: res,
          numNotificationOrders: res.length,
          notifyOrderStyle: "1px solid white",
        });
      });
  };

  logout = () => {
    this.props.logout();
  };

  componentDidMount() {
    if (this.props.auth.user.activo === false) {
      this.logout();
      swal({
        type: "error",
        title: "¡Ocurrió un error!",
        text: "Acceso Restringido ",
      });
    }
    this.fetchOrders();
    this.fetchNotificationsOrders();
    this.fetchNotifications();
    this.fetchNotificationsTasks();
    this.fetchIncidencesNotifications();
    this.uuid = Feathers.service("notificacion").on(
      "created",
      (notification) => {
        this.fetchIncidencesNotifications();
        this.fetchNotifications();
        this.fetchNotificationsOrders();
        this.fetchNotificationsTasks();
      }
    );
  }

  componentWillUnmount() {
    Feathers.service("notificacion").off("created");
  }

  readorunreadNotifications = async () => {
    const serviceNot = Feathers.service("notificacion");
    this.state.notificationsToDelete.map(async (v, i) => {
      await serviceNot.patch(v, {
        status: 0,
      });
    });

    await this.fetchNotifications();

    await this.setState({
      notificationsToDelete: [],
    });
  };

  readOrdersNotifications = async () => {
    const serviceNot = Feathers.service("notificacion");
    this.state.notificationsOrdersToDelete.map(async (v, i) => {
      await serviceNot.patch(v, {
        status: 0,
      });
    });

    await this.fetchNotificationsOrders();

    await this.setState({
      notificationsOrdersToDelete: [],
    });
  };

  readTasksNotifications = async () => {
    const serviceNot = Feathers.service("notificacion");
    this.state.notificationsTasksToDelete.map(async (v, i) => {
      await serviceNot.patch(v, {
        status: 0,
      });
    });

    await this.fetchNotificationsTasks();

    await this.setState({
      notificationsTasksToDelete: [],
    });
  };

  handlePortalClick = () => {
    this.setState({ openPortal: !this.state.openPortal });
    this.readorunreadNotifications();
  };

  handlePortalClose = () => {
    this.setState({ openPortal: false });
    this.readorunreadNotifications();
  };

  //TODO: NOTIS TASKS
  handleTasksPortalClick = () => {
    this.setState({ openTasksPortal: true });
    this.readTasksNotifications();
  };

  handleTasksPortalClose = () => {
    this.setState({ openTasksPortal: false });
    this.readTasksNotifications();
  };

  //TODO: NOTIS ORDERS
  handleOrdersPortalClick = () => {
    this.setState({ openOrdersPortal: true });
    this.readOrdersNotifications();
  };

  handleOrdersPortalClose = () => {
    this.setState({ openOrdersPortal: false });
    this.readOrdersNotifications();
  };

  handleIncidencesPortalClick = () => {
    this.setState({ openIncidencesPortal: !this.state.openIncidencesPortal });

    //this.readorunreadNotifications()
  };

  handleIncidencesPortalClose = async () => {
    await this.setState({ openIncidencesPortal: false });
    await this.fetchIncidencesNotifications();
    //this.readorunreadNotifications()
  };

  handleAccordionClick = (i) => {
    const index = i;
    let activeIndex = [...this.state.activeIndex];

    if (activeIndex.indexOf(index) === -1) {
      activeIndex.push(index);
    } else {
      let pos = activeIndex.indexOf(index);
      activeIndex.splice(pos, 1);
    }

    this.setState({ activeIndex });
  };

  toggleVisibility(size) {
    if (size === "min") {
      this.setState({ minVisible: !this.state.minVisible });
    } else {
      this.setState({ visible: !this.state.visible });
    }
  }

  renderLinks(mobile = false, flinks = links) {
    //console.log("links:", flinks);
    return flinks.map((r, i) => {
      if (r.options) {
        //console.log(r, 'roptions')
        if (this.checkIsInRole(r)) {
          return (
            <Accordion
              key={i}
              style={{
                border: "2px solid #393e46",
                borderRadius: "8px",
                margin: 10,
              }}
            >
              <Accordion.Title
                style={{
                  color: "white",
                  backgroundColor:
                    this.state.activeIndex.indexOf(r.id) !== -1
                      ? "#393e46"
                      : "transparent",
                }}
                active={this.state.activeIndex.indexOf(r.id) !== -1}
                index={i}
                onClick={() => this.handleAccordionClick(r.id)}
              >
                <span style={{ marginLeft: 20 }}>{r.displayName}</span>
                <span style={{ float: "right", marginRight: 11 }}>
                  {this.state.activeIndex.indexOf(r.id) !== -1 && (
                    <Icon name="angle up" />
                  )}
                  {this.state.activeIndex.indexOf(r.id) === -1 && (
                    <Icon name="angle down" />
                  )}
                </span>
              </Accordion.Title>
              <Accordion.Content
                active={this.state.activeIndex.indexOf(r.id) !== -1}
              >
                {this.renderMenuItem(r.options, mobile)}
              </Accordion.Content>
            </Accordion>
          );
        }
      }
      if (this.checkIsInRole(r)) {
        return (
          <Menu.Item
            name={r.name}
            onClick={() => this.handleClick(r.path, mobile)}
            key={i}
          >
            {r.icon && <Icon name={r.icon} />}
            {r.displayName}
          </Menu.Item>
        );
      }
    });
  }

  checkIsInRole = (link) => {
    // console.log("checkIsInRolecheckIsInRole:", link);
    // console.log(" this.props.auth.user.profiles.length:", this.props.auth.user);
    let res = false;
    //const perfil_id = this.props.auth.user.profiles[0];
    //const permission = this.props.auth.user.permissions;
    // console.log("---", this.props.auth.user);
    const currentProfiles = this.props.auth.user.profiles;
    // //Recupera todos los permisos del perfil/rol
    // const currentPermission = permission.map((item) => {
    //   if (item.perfil_id === perfil_id) {
    //     return item.permiso_id;
    //     // break;
    //   }
    // });

    //console.log({currentPermission, link}, 'currentpermision')

    if (
      currentProfiles.includes(1) ||
      currentProfiles.includes(2) ||
      currentProfiles.includes(10) ||
      currentProfiles.includes(25) ||
      currentProfiles.includes(24)
    ) {
      const currentPermission = this.props.auth.user.currentPermissions;
      for (let i = 0; i < currentPermission.length; i++) {
        if (
          link.permissions.find(
            (element) =>
              element == currentPermission[i].permiso_id || element == "nothing"
          )
        ) {
          res = true;
          break;
        }
      }
      return res;
    } else {
      if (link.name !== "tasks") {
        const currentPermission = this.props.auth.user.currentPermissions;
        for (let i = 0; i < currentPermission.length; i++) {
          if (
            link.permissions.find(
              (element) =>
                element == currentPermission[i].permiso_id || element == "nothing"
            )
          ) {
            res = true;
            break;
          }
        }
        return res;
      }
    }

    // let res = false;
    // // let requeridPermissions: Array = undefined;
    // const perfil_id = this.props.auth.user.profiles[0];
    // const permission = this.props.auth.user.permissions;

    // //Recupera todos los permisos del perfil/rol
    // const currentPermission = permission.map(item => {
    //   if (item.perfil_id === perfil_id) {
    //     return item.permiso_id;
    //     // break;
    //   }
    // });

    // console.log("currentPermission:", currentPermission);
    // console.log("link.permissions:", link.permissions);

    // const requeridPermissions = link.permissions && link.permissions;

    // requeridPermissions.map(item => {
    //   console.log("Yeahhhhhh:", item);
    // });

    // const permissionIds =  currentPermission.map((item) =>)
    // console.log("perfil_idperfil_idperfil_id:", perfil_id);
    // console.log("resultresult:", currentPermission);
    // console.log("linklinklinklink:", requeridPermissions);
    // return true;
  };

  renderMenuItem = (options, mobile) => {
    //console.log(options, 'optios')
    return options.map((o, keyop) => {
      if (this.checkIsInRole(o)) {
        if (o.options) {
          return this.renderLinks(undefined, [o]);
        } else {
          return (
            <Menu.Item
              style={{ backgroundColor: "#393e46", padding: 10, margin: 10 }}
              key={keyop}
              name={o.name}
              onClick={() => this.handleClick(o.path, mobile)}
            >
              {o.icon && <Icon name={o.icon} />}
              <span>{o.displayName}</span>
            </Menu.Item>
          );
        }
      }
    });
  };

  handleClick(path, mobile) {
    this.props.history.push(path);

    if (mobile) {
      this.setState({ minVisible: false });
    }
  }

  handleNotifyCheck = (event, data, id) => {
    if (data.checked) {
      this.state.notificationsToDelete.push(id);
    } else {
      let index = this.state.notificationsToDelete.findIndex((element) => {
        return element == id;
      });
      this.state.notificationsToDelete.splice(index, 1);
    }
  };

  renderNotificationContent = () => {
    return this.state.notificacionesOn.map((v, i) => {
      return (
        <div key={i}>
          <Checkbox
            label={v.mensaje}
            onChange={(event, data) =>
              this.handleNotifyCheck(event, data, v.id)
            }
          />
        </div>
      );
      /*  if (
        this.props.auth.user.profiles.find((element) => element == v.perfil_id)
      ) {
       
      } */
    });
  };

  handleNotifyTasksCheck = (event, data, id) => {
    if (data.checked) {
      this.state.notificationsTasksToDelete.push(id);
    } else {
      let index = this.state.notificationsTasksToDelete.findIndex((element) => {
        return element == id;
      });
      this.state.notificationsTasksToDelete.splice(index, 1);
    }
  };

  renderNotificationTasksContent = () => {
    return this.state.notificationTasks.map((v, i) => {
      return (
        <div key={i}>
          <Checkbox
            label={v.mensaje}
            onChange={(event, data) =>
              this.handleNotifyTasksCheck(event, data, v.id)
            }
          />
        </div>
      );
    });
  };

  handleNotifyOrdersCheck = (event, data, id) => {
    if (data.checked) {
      this.state.notificationsOrdersToDelete.push(id);
    } else {
      let index = this.state.notificationsOrdersToDelete.findIndex(
        (element) => {
          return element == id;
        }
      );
      this.state.notificationsOrdersToDelete.splice(index, 1);
    }
  };

  renderNotificationOrdersContent = () => {
    return this.state.notificationOrders.map((v, i) => {
      return (
        <div key={i}>
          <Checkbox
            label={v.mensaje}
            onChange={(event, data) =>
              this.handleNotifyOrdersCheck(event, data, v.id)
            }
          />
        </div>
      );
    });
  };

  handleNotifyIncidenceClick = async (adjunto, id) => {
    Feathers.service("notificacion").patch(id, {
      status: 0,
    });
    this.props.history.push("/reports/incidences/view/" + adjunto);
    //window.location = await '/reports/incidences/view/' + adjunto
  };

  removeNotifications = async () => {
    console.log(this.state.notificacionesIncidendesOn);

    await this.state.notificacionesIncidendesOn.forEach(
      async (notificacion) => {
        await Feathers.service("notificacion")
          .patch(notificacion.id, {
            status: 0,
          })
          .then(() => {
            this.setState({
              noIncidencesNotification: 0,
              notificacionesIncidendesOn: [],
            });
          });
      }
    );
  };

  renderIncidenceNotificationContent = () => {
    return this.state.notificacionesIncidendesOn.map((v, i) => {
      return (
        <div key={i}>
          <Button
            icon="eye"
            label={v.mensaje}
            onClick={() => this.handleNotifyIncidenceClick(v.adjunto, v.id)}
          />
        </div>
      );
      /* if (
        this.props.auth.user.profiles.find((element) => element == v.perfil_id)
      ) {
       
      } */
    });
  };

  render() {
    const { openPortal } = this.state;
    const { openOrdersPortal } = this.state;
    const { openTasksPortal } = this.state;
    const { openIncidencesPortal } = this.state;
    const { visible, minVisible } = this.state;
    return (
      <div>
        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
          <Sidebar.Pushable>
            <div
              style={{ width: "100%", height: 60, backgroundColor: "#F41B00" }}
            >
              <Button
                icon="content"
                onClick={() => this.setState({ minVisible: true })}
                style={{ backgroundColor: "#e31b02", color: "white" }}
              ></Button>
            </div>
            <Sidebar
              as={Menu}
              animation="overlay"
              width="thin"
              visible={minVisible}
              icon="labeled"
              vertical
              inverted
              style={{ backgroundColor: "#222831" }}
            >
              {this.renderLinks(true)}
              <Menu.Item name="camera" onClick={this.logout}>
                <Icon name="power" />
                <p>Cerrar Sesión</p>
              </Menu.Item>
            </Sidebar>
            <Sidebar.Pusher
              style={{ height: "calc(100vh - 60px)", overflow: "auto" }}
              onClick={() => this.setState({ minVisible: false })}
            >
              <Segment basic>
                <Switch>{routes}</Switch>
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Responsive>

        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <div>
            <Segment
              size="mini"
              inverted
              style={{
                borderRadius: 0,
                marginBottom: 0,
                backgroundColor: "#F41B00",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                icon="content"
                onClick={() => this.toggleVisibility("max")}
                style={{ backgroundColor: "#e31b02", color: "white" }}
              ></Button>{" "}
              {/* Bingham */}
              <img
                src={require("../resources/images/logo2.png")}
                style={{ width: "10%", height: "10%" }}
              />
              <div>
                {/* <Button
                  style={{
                    border: this.state.notifyOrderStyle,
                    backgroundColor: "#e31b02",
                    color: "white",
                  }}
                  onClick={this.handleOrdersPortalClick}
                  as="label"
                >
                  <Icon name="calendar times"></Icon>{" "}
                  {this.state.numNotificationOrders > 0 &&
                    this.state.numNotificationOrders}
                </Button> */}
                {/* <Button
                  style={{
                    border: this.state.notifyTasksStyle,
                    backgroundColor: "#e31b02",
                    color: "white",
                  }}
                  onClick={this.handleTasksPortalClick}
                  as="label"
                >
                  <Icon name="alarm"></Icon>{" "}
                  {this.state.numNotificationTasks > 0 &&
                    this.state.numNotificationTasks}
                </Button> */}
                {/* <Button
                  style={{
                    border: this.state.notifyStyle,
                    backgroundColor: "#e31b02",
                    color: "white",
                  }}
                  onClick={this.handlePortalClick}
                  as="label"
                >
                  <Icon name="alarm"></Icon>{" "}
                  {this.state.noNotificationes > 0 &&
                    this.state.noNotificationes}
                </Button> */}
                <Button
                  style={{
                    border: this.state.notifyIncidenceStyle,
                    backgroundColor: "#e31b02",
                    color: "white",
                  }}
                  onClick={this.handleIncidencesPortalClick}
                  as="label"
                >
                  <Icon name="warning"></Icon>{" "}
                  {this.state.noIncidencesNotification > 0 &&
                    this.state.noIncidencesNotification}
                </Button>
              </div>
              <Portal
                onClose={this.handleOrdersPortalClose}
                open={openOrdersPortal}
              >
                <Segment
                  style={{
                    right: "19%",
                    position: "fixed",
                    top: "5%",
                    zIndex: 1000,
                  }}
                >
                  <Header>Pedidos</Header>
                  {this.renderNotificationOrdersContent()}
                </Segment>
              </Portal>
              <Portal
                onClose={this.handleTasksPortalClose}
                open={openTasksPortal}
              >
                <Segment
                  style={{
                    right: "14%",
                    position: "fixed",
                    top: "5%",
                    zIndex: 1000,
                  }}
                >
                  <Header>Notificaciones de tareas</Header>
                  {this.renderNotificationTasksContent()}
                </Segment>
              </Portal>
              <Portal onClose={this.handlePortalClose} open={openPortal}>
                <Segment
                  style={{
                    right: "9%",
                    position: "fixed",
                    top: "5%",
                    zIndex: 1000,
                  }}
                >
                  <Header>Notificaciones</Header>
                  {this.renderNotificationContent()}
                </Segment>
              </Portal>
              <Portal
                onClose={this.handleIncidencesPortalClose}
                open={openIncidencesPortal}
              >
                <Segment
                  style={{
                    right: "3%",
                    position: "fixed",
                    top: "5%",
                    zIndex: 1000,
                  }}
                >
                  <Header>Incidencias</Header>
                  {this.renderIncidenceNotificationContent()}
                  <center style={{ padding: 18 }}>
                    {" "}
                    <Button
                      onClick={async () => {
                        await this.removeNotifications();
                        await this.handleIncidencesPortalClose();
                        await this.fetchIncidencesNotifications();
                      }}
                    >
                      Marcar notificaciones como leídas
                    </Button>
                    ;{" "}
                  </center>
                </Segment>
              </Portal>
            </Segment>

            <Sidebar.Pushable
              style={{ height: "calc(100vh - 60px)", overflow: "hidden" }}
            >
              {/* Barra lateral */}
              <Sidebar
                style={{ width: 260, backgroundColor: "#222831" }}
                as={Menu}
                borderless
                animation="push"
                visible={visible}
                vertical
                inverted
              >
                {this.renderLinks()}
                <Menu.Item name="camera" onClick={this.logout}>
                  <Icon name="power" size="large" />
                  <p style={{ fontWeight: "bold" }}>Cerrar Sesión</p>
                </Menu.Item>
                <div
                  style={{
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "14px",
                    marginBottom: "15px",
                  }}
                >
                  <p>v2.7.7</p>
                </div>
              </Sidebar>

              {/* Barra lateral cuando sale-o se esconde */}
              <Sidebar.Pusher
                style={{
                  padding: 10,
                  width: visible ? "calc(100vw - 260px )" : "100%",
                  height: "calc(100vh - 60px)",
                  overflow: "scroll",
                  background: "",
                }}
              >
                {/* Contenedor rutas */}
                <Segment style={{}} basic>
                  {routes}
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(feathersAuthentication.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
