import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import TaskStatus from "../../components/Tasks/TaskStatus";
import {
  Button,
  Grid,
  Table,
  Header,
  Icon,
  Segment,
  Modal,
  Container,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../components/Tasks/Construction/ConstructionTask";
import Notifications, { notify } from "react-notify-toast";
import Config from "../../config/index";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { exportToXls } from "../../utils/Utils";
import ObservationModal from "../../components/ObservationModal";
import Toggle from "react-toggle";
import TasksFilter from "../../components/FIlters/TasksFilter";

const SERVICE = "construccion";

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class ConstructionPoliuretanoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishingTask: false,
      loading: false,
      loadingSearch: false,
      loadingSAESearch: false,
      tasks: [],
      total: 0,
      page: 0,
      pageSize: 10,
      ///
      ready: false,
      task: null,
      modalOpen: false,
      imageToOpen: "",
      results: null,

      filters: ["pedido", "partida", "rodillo", "costumer", "material", "entrega"],
      filterOptions: {
        cliente_id: null,
        material_id: null,
        ref_sae: null,
        pedido_id: null,
        partida_id: null,
      },
      filterValues: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },
      cliente: null,
      material: null,
      clienteLoading: null,
      materialLoading: null,
      clienteResults: null,
      materialResults: null,

      sae: "",
      resultsSae: null,
      colecciones: [],
      totalesARealizar: 0,
      modalOpened: false,
      currentRow: {},
      columna: "",
      sortDirection: false,
      showSae: false,
      shouldExport: false,
      tableColumns: [
        {
          Header: "OT",
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return row.original.rodillo.partida.pedido.ref_sae;
          },
        },
        {
          Header: "Partida",
          headerStyle: { fontSize: 16 },
          id: "partida_id",
          accessor: (d) => {
            if (d.rodillo.partida_id) return d.rodillo.partida_id;
            return "---";
          },
        },
        {
          Header: "Rodillo",
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            if (row.original.rodillo.partida.dibujo) {
              return (
                <a
                  href="#"
                  onClick={() =>
                    this.handleOpen(row.original.rodillo.partida.dibujo.imagen)
                  }
                >
                  {" "}
                  {row.original.rodillo.id}
                </a>
              );
            } else {
              return <span>{row.original.rodillo.id}</span>;
            }
          },
        },
       
        {
          Header: "Cliente",
          maxWidth: 250,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return row.original.rodillo.partida.pedido.cliente.nombre;
          },
        },
        {
          Header: "Material",
          maxWidth: 250,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return row.original.rodillo.partida.material.nombre;
          },
        },
        {
          Header: "Fecha Entr.",
          resizable: false,
          maxWidth: 250,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            if (row.original.rodillo.partida.pedido)
              return (
                <span>
                  {moment(
                    row.original.rodillo.partida.pedido.fecha_entrega
                  ).format("DD-MM-YYYY")}
                </span>
              );

            return "---";
          },
        },
        {
          Header: "Dureza",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            if (row.original.rodillo.partida.dureza)
              return <span>{row.original.rodillo.partida.dureza.valor}</span>;

            return "---";
          },
        },
        {
          Header: "DN",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return <span>{row.original.rodillo.partida.dn}</span>;
          },
        },
        {
          Header: "DE",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return <span>{row.original.rodillo.partida.de}</span>;
          },
        },
        {
          Header: "LC",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return <span>{row.original.rodillo.partida.lc}</span>;
          },
        },
        {
          Header: "LT",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return <span>{row.original.rodillo.partida.lt}</span>;
          },
        },
        {
          Header: "Kilos",
          resizable: false,
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            if (row.original.rodillo.partida.kilos)
              return <span>{row.original.rodillo.partida.kilos}</span>;

            return "---";
          },
        },
        {
          Header: "Cant.",
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return row.original.rodillo.partida.cantidad;
          },
        },
        {
          Header: "Acciones",
          sortable: false,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <>
                <Button
                  onClick={() => this.viewTask(row.original.id)}
                  icon="play"
                  basic
                  size="mini"
                  color="blue"
                  content="Ver"
                />
                <Button
                  onClick={() => this.openModal(row)}
                  icon="eye"
                  size="mini"
                  basic
                  color="blue"
                  content="Obser."
                  style={{ backgroundColor: "#0098f0" }}
                />
              </>
            );
          },
        },
      ],
    };

    this.renderList = this.renderList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.initTask = this.initTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
  }

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Producto":
        array.sort((a, b) => {
          const nameA = a.rodillo.partida.producto.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.rodillo.partida.producto.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "SAE":
        array.sort((a, b) => {
          return (
            a.rodillo.partida.pedido.ref_sae - b.rodillo.partida.pedido.ref_sae
          );
        });
        break;
      case "Cliente":
        array.sort((a, b) => {
          const nameA = a.rodillo.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.rodillo.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Material":
        array.sort((a, b) => {
          let nameA = a.rodillo.partida.material.nombre
            ? a.rodillo.partida.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase
          let nameB = b.rodillo.partida.material.nombre
            ? b.rodillo.partida.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Cantidad":
        array.sort((a, b) => {
          let A = a.rodillo.partida.cantidad;
          let B = b.rodillo.partida.cantidad;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  goBack() {
    this.setState({ task: null }, (_) => this.fetchData(this.state));
  }

  openModal = (row) => {
    this.setState({
      modalOpened: true,
      currentRow: row.original.rodillo.partida.observaciones,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  };

  handleOpen = (logicImageUrl) => {
    var imageToOpen = Config.apiUrl + logicImageUrl;
    this.setState({ modalOpen: true, imageToOpen: imageToOpen });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount() {
    // this.checkActiveTasks(); // no tiene sentido ir por la activa si ahora se podrán realizar varias a la vez
    this.initComponent();
    this.fetchData();
  }

  initComponent = (_) => {
    this.setState({
      ready: true,
    });
  };

  checkActiveTasks() {
    Feathers.service(SERVICE)
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          if (res.data[0].rodillo.esMancheta === 0) {
            this.setState({
              task: res.data[0],
              ready: true,
            });
          } else {
            this.setState({
              ready: true,
            });
          }
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  async fetchData(state = {}) {
    this.setState({
      loading: true,
    });

    /**
     * aqui empieza  lo necesario para traer nada más los poliuretano
     * */

    let partidas = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: {
          $in: [3, 4],
        },
        $select: ["id"],
        //$limit: pageSize,
        //$skip: pageSize * state.page,
      },
    });
    let partidas_validas_ids = partidas.map((v) => v.id);

    let rodillos = await Feathers.service("rodillo").find({
      query: {
        $limit: "-1",
        partida_id: {
          $in: partidas_validas_ids,
        },
        $or: [{ status_tarea_id: null }, { status_tarea_id: 1 }],
        $select: ["id "],
      },
    });

    let rodillosValidos = rodillos.map((v) => v.id);

    /**
     * aqui termina  lo necesario para traer nada más los poliuretano
     * */
    let pageSize = state.pageSize || 10;
    let query = {
      rodillo_id: {
        $in: rodillosValidos,
      },
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      $or: [{ status_tarea_id: null }, { status_tarea_id: 1 }],
    };

    if (this.state.filterOptions.material_id !== null) {
      let partidas_de_cliente = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          producto_id: {
          $in: [3, 4],
        },
          material_id: this.state.filterOptions.material_id,
        },
      });

      let rodillos_del_cliente_con_las_partidas_validas = await Feathers.service(
        "rodillo"
      ).find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_de_cliente.map((v) => v.id),
          },
        },
      });

      let los_id_de_los_rodillos_del_cliente = rodillos_del_cliente_con_las_partidas_validas.map(
        (v) => v.id
      );

      query["rodillo_id"] = {
        $in: los_id_de_los_rodillos_del_cliente,
      };
    }

    if (this.state.filterOptions.cliente_id !== null) {
      let pedidos_del_cliente = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          cliente_id: this.state.filterOptions.cliente_id,
        },
      });

      let pedidos_ids = pedidos_del_cliente.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          producto_id: {
            $in: [3, 4],
          },
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.pedidoValue) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          id: this.state.filterValues.pedidoValue
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.partidaValue) {
      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: this.state.filterValues.partidaValue,
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.rodilloValue) {
      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          id: this.state.filterValues.rodilloValue,
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    if (this.state.filterValues.fechaEntrega) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          fecha_entrega: this.state.filterValues.fechaEntrega,
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      let rodillos_de_las_partidas = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          partida_id: {
            $in: partidas_ids,
          },
        },
      });

      let rodillos_ids = rodillos_de_las_partidas.map((v) => v.id);

      query["rodillo_id"] = {
        $in: rodillos_ids,
      };
    }

    Feathers.service(SERVICE)
      .find({
        query,
      })
      .then((res) => {

        let resdata = res.data ? res.data : res;

        resdata = this.handleSort(resdata);
        if (this.state.columna === "") {
          resdata.sort((a, b) => {
            const nameA = a.rodillo.partida.pedido.fecha_entrega.toUpperCase();
            const nameB = b.rodillo.partida.pedido.fecha_entrega.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }
        if (!this.state.shouldExport) {
          this.setState({
            //colecciones,
            tasks: resdata,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        }

        if (this.state.shouldExport) {
          exportToXls(resdata, "task_construction_poliuretanos");
          this.setState({
            shouldExport: false,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        }
      });

  }

  removeDuplicate = (arr) => {
    return Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse);
  };

  viewTask(task) {
    Feathers.service("construccion")
      .get(task)
      .then(
        (task) => {
          //console.log(task, "tarea");
          //task.pendientes = v.poliuretanos;
          this.setState({ task });
        },
        () => this.fetchData(this.state)
      );
  }

  initTask(task) {
    /*
      
      en algun lado vamos a tener que guardar el número de elementos que son pendientes y que van a ser realizados, porque
      el usuario se puede salir de la pantalla de construcción 

      entonces, cuando se sale, se pierde el arreglo de los pendientes y se pierde tambíen el número de unidades que se quiere realizar
      
      */
    Feathers.service(SERVICE)
      .patch(task.id, {
        hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
        status_tarea_id: 1,
        usuario_id: this.props.user.id,
        //aproducir: res.value,
      })
      .then((res) => {
        Feathers.service(SERVICE)
          .get(res.id)
          .then(
            (task) => this.setState({ task }),
            () => this.fetchData(this.state)
          );
      });
  }

  finishTask() {
    /*
      
      Aqui es donde vamosa  controlar que hacer con las otras unidades pendientes en caso de que se haya 
      dicho que se realizaran.

      vamos a ver cuantas dijo que se haran y cuantas quedan pendientes, para de las pendientes realizar las dichas

      
      */

    // return

    swal({
      title: "¿Finalizar tarea de construcción?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (res.value) {
        await this.setState({
          finishingTask: true,
        });
        Feathers.service(SERVICE)
          .patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          })
          .then(async (res) => {
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(6);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de constucción poliuretano #${this.state.task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }
            this.setState(
              {
                task: null,
                finishingTask: false,
              },
              () => this.fetchData(this.state)
            );
          });

        /* if (this.state.task.aproducir === 1) {
        } */

        //if (this.state.task.aproducir >= 2) {
        // como se seleccionó mas de uno al producir, tenemos que buscar los que quedan pendientes.

        /*
                      Tenemos el objeto de colecciones.

                      la colección tipo 4 es poliuretano rodillo
                      la tipo 3 es poliuretano pieza.

                      Tenemos que agarrar la coleccion del tipo de producto que es la tarea 
                    */

        /* let tipo_producto = this.state.task.rodillo.partida.producto_id;

        let coleccion = this.state.colecciones.find((o, i) => {
          if (o.tipo === tipo_producto) return o;
        }); */

        /* coleccion = JSON.parse(JSON.stringify(coleccion));

        // ya que tenemos la colección, tenemos que agarrar los pendientes

        let unidades_pendientes = JSON.parse(
          JSON.stringify(coleccion.poliuretanos)
        ); */

        /*
                    
                      de los pendientes, tenemos que sacar el corriente, es decir
                      el que está siendo construido primero, el actual, 
                      la task, pues.
                    */

        /* unidades_pendientes = unidades_pendientes.filter((v) => {
          if (v.id !== this.state.task.id) return v;
        }); */

        /*

                      tenemos que agarrar solo de unidades pendientes los que el usuario seleccinó como a producir 


                      recordemos que "a producir" toma en cuenta la unidad produciendose, por lo que
                      tendremos que restarle un -1
                    
                    */

        /* let unidades_a_producir = unidades_pendientes.slice(
          0,
          this.state.task.aproducir - 1
        ); */

        /*
                    
                    ya que tenemos las unidades a producir, hay que actualizarlas, con los mismos datos de lo
                    que llamamos unidad corriente;  me gustaría más llamarla, unidad original.


                    esos datos son:

                    venda_id
                    dn_final
                    de_final
                    
                    la hora inicio y hora fin serán dinámicas
                    TODO: hay que pensar bien eso de hora inicio y hora fin
                    */

        /* let atributos_a_actualizar = {
          de_final: this.state.task.de_final,
          dn_final: this.state.task.dn_final,
          venda_id: this.state.task.venda_id,
        }; */

        /*
                    Vamos a, con un for of, recorrer los pendientes e iremos actualizandolos
                    */

        /* for (let pendiente of unidades_a_producir) {
          let pendiente_actualizado = await Feathers.service(
            "construccion"
          ).patch(pendiente.id, {
            de_final: atributos_a_actualizar.de_final,
            dn_final: atributos_a_actualizar.dn_final,
            venda_id: atributos_a_actualizar.venda_id,
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
          });

          let pendiente_terminado = await Feathers.service(
            "construccion"
          ).patch(pendiente.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          });
        } */

        /*
                    
                    ahora terminaremos el original

                    */

        /* try {
          let original_terminado = await Feathers.service(
            "construccion"
          ).patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          });
        } catch (error) {
          console.log(error);
        }

        this.setState(
          {
            task: null,
            finishingTask: false,
          },
          () => this.fetchData(this.state)
        ); */
        //}
      }
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("construccion")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        this.setState({ task: null });
        notify.show("Incidencia registrada", "success");
        this.fetchData(this.state);
      });
  }

  updateTask() {
    Feathers.service(SERVICE)
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  renderActions = (row) => {
    row = row.original;

    return (
      <>
        <Button
          onClick={() => this.viewTask(row)}
          basic
          icon="play"
          size="mini"
          color="blue"
          content="Ver"
        />
      </>
    );
  };


  handleClienteResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          cliente_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          material_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleClienteSearchChange = (e, { value }) => {
    this.setState({ clienteLoading: true, cliente: value });

    this.timeout = setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  onPartidaChange = (partida) => {
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        partidaValue: partida,
      },
    }, () => this.fetchData(this.state));
  };

  onChangeRodillo = (rodillo) => {
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        rodilloValue: rodillo,
      },
    }, () => this.fetchData(this.state));
  };

  onChangePedido = (pedido) => {
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        pedidoValue: pedido,
      },
    }, () => this.fetchData(this.state));
  };

  onDateChange = (date) => {
    const dateFormat = moment(date).format('YYYY-MM-DD')
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        fechaEntrega: dateFormat,
      },
    }, () => this.fetchData(this.state));
  };

  resetClienteComponent = () => {
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });
  }

  resetMaterialComponent = () => {
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  }

  handleCleanButton = async () => {
    this.resetClienteComponent();
    this.resetMaterialComponent();
    this.setState({
      filterValues: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },
      filterOptions: {
        cliente_id: null,
        material_id: null,
        ref_sae: null,
        pedido_id: null,
        partida_id: null,
      }
    }, () => {
      this.fetchData(this.state)
    });
  };

  renderList() {
    return (
      <div>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="small"
        >
          <Header icon="browser" content="Rodillo" />
          <Modal.Content>
            <Container fluid textAlign="center">
              <img src={this.state.imageToOpen} />
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.handleClose} inverted>
              <Icon name="checkmark" /> Ok
            </Button>
          </Modal.Actions>
        </Modal>

        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="building"
                content="Construcción - Poliuretanos"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                <TasksFilter
                    loading={this.state.loading}
                    clienteLoading={this.state.clienteLoading}
                    filters={this.state.filters}
                    costumer={this.state.cliente || ""}
                    costumers={this.state.clienteResults}
                    onSelectCostumer={this.handleClienteResultSelect}
                    onSearchCostumerChange={this.handleClienteSearchChange}
                    costumerResultRenderer={renderClientSearchResults}
                    partidaValue={this.state.filterValues.partidaValue || ""}
                    onChangePartida={this.onPartidaChange}
                    rodilloValue={this.state.filterValues.rodilloValue || ""}
                    onChangeRodillo={this.onChangeRodillo}
                    fechaEntrega={this.state.filterValues.fechaEntrega}
                    onDateChange={this.onDateChange}
                    materialLoading={this.state.materialLoading}
                    materials={this.state.materialResults}
                    material={this.state.material ? this.state.material : ""}
                    handleMaterialResultSelect={this.handleMaterialResultSelect}
                    handleMaterialSearchChange={this.handleMaterialSearchChange}
                    renderMaterialSearchResults={renderMaterialSearchResults}
                    pedidoValue={this.state.filterValues.pedidoValue || ""}
                    onPedidoChange={this.onChangePedido}
                  />
                  
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        this.setState({ shouldExport: true }, () => {
                          this.fetchData(this.state);
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Mostrar OT</label>
                    {/* <input type="checkbox" name="showSAE"/> */}
                    <Toggle
                      icons={false}
                      onClick={() => {
                        this.handleSaeToggle();
                      }}
                    ></Toggle>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              {/* <this.renderListTable /> */}
              <ReactTable
                columns={
                  this.state.showSae
                    ? this.state.tableColumns
                    : this.state.tableColumns.slice(1)
                }
                manual
                data={this.state.tasks}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState(
                    {
                      columna: column,
                      sortDirection: direction,
                    },
                    () => {
                      console.log(this.state);
                    }
                  );
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ObservationModal
          data={this.state.currentRow}
          isOpened={this.state.modalOpened}
          onClose={this.closeModal}
        />
      </div>
    );
  }

  render() {
    if (this.state.ready) {
      if (this.state.task) {
        //goBack={this.goBack} initTask={this.initTask} finishTask={this.finishTask} task={this.state.task} cancelTaskByIncidence={this.cancelTaskByIncidence}/
        return (
          <TaskContainer
            loading={this.state.finishingTask}
            goBack={this.goBack}
            initTask={this.initTask}
            finishTask={this.finishTask}
            task={this.state.task}
            updateTask={this.updateTask}
            cancelTaskByIncidence={this.cancelTaskByIncidence}
          />
        );
      }

      return this.renderList();
    }

    return <span>Cargando...</span>;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ConstructionPoliuretanoContainer);
