import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import { Button, Form, Grid, Popup, Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import PartidaModal from "./PartidaModal";
import { exportToXls } from "../../utils/Utils";

const STAGES = [
  { key: "0", text: "INCIDENCIA", value: "0" },
  { key: "1", text: "DESVISTE", value: "1" },
  { key: "4", text: "VULCANIZADO", value: "4" },
  { key: "5", text: "DESMONTE", value: "5" },
  { key: "6", text: "DESBASTE", value: "6" },
  { key: "7", text: "RECTIFICADO", value: "7" },
  { key: "8", text: "CORTE", value: "8" },
  { key: "9", text: "CONSTRUCCION", value: "9" },
  { key: "10", text: "FINALIZADO", value: "10" },
  { key: "11", text: "PENDIENTE VULCANIZADO", value: "11" },
];

const getStageName = (id) => {
  switch (id) {
    case 0:
      return "Incidencia";
    case 1:
      return "Desviste";
    case 2:
      break;
    case 3:
      return "Molino";
    case 4:
      return "Vulcanizado";
    case 5:
      return "Desmonte";
    case 6:
      return "Desbaste";
    case 7:
      return "Rectificado";
    case 8:
      return "Corte";
    case 9:
      return "Construcción";
    case 10:
      return "Finalizado";
    case 11:
      return "Pend. Vulcanizado";
    case null:
      return "SIN SEGUIMIENTO";

    default:
      break;
  }
};
export default class PartidasList extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      total: 0,
      page: 0,
      loading: false,
      shouldExport: false,
      currentRow: {},
      isEditOpened: false,
      // results of the search querys
      results: null,
      // result objects

      client: "",

      modalOpened: false,
      pageSize: 100,
      filterOptions: {
        id: null,
        ot: null,
        stageId: null,
      },
      $sortDirection: 1,
      // loading of the search bars
      loadingSearch: false,
      columna: "",
      sortDirection: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(row) {
    this.setState({
      modalOpened: true,
      currentRow: row,
    });
  }

  closeModal() {
    this.setState({
      modalOpened: false,
    });
  }

  async fetchData(state = {}) {
    let pageSize = 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: 100 * state.page,
      $sort: {
        id: -1,
      },
    };

    if (this.state.filterOptions.id) {
      query["id"] = this.state.filterOptions.id;
    }

    if (this.state.filterOptions.ot) {
      query["pedido_id"] = this.state.filterOptions.ot;
    }

    if (this.state.filterOptions.stageId) {
      const rodillos = await Feathers.service("rodillo").find({
        query: {
          $limit: "-1",
          stage: this.state.filterOptions.stageId,
        },
      });
      const partidasId = rodillos.map((r) => r.partida_id);

      query["id"] = {
        $in: partidasId,
      };
    }

    let filters = {
      stage_id: this.state.filterOptions.stageId,
      pedido_id: this.state.filterOptions.ot,
      partida_id: this.state.filterOptions.id,
    };

    this.setState({ loading: true }, () => {
      Feathers.service("partida")
        .find({
          query,
        })
        .then((res) => {
          console.log("res", res);
          //   res = this.handleSort(res);
          if (!this.state.shouldExport) {
            this.setState({
              data: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }

          if (this.state.shouldExport) {
            console.log("exporting", res);
            exportToXls([], "partidas_admin", filters);
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  }

  handleCleanButton = () => {
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
          ot: null,
          stageId: null,
        },
      },
      () => {
        this.fetchData(this.state);
      }
    );
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>OT</label>
                  <Input
                    type="number"
                    value={this.state.filterOptions.ot || ""}
                    onChange={(event, data) => {
                      this.setState({
                        filterOptions: {
                          ...this.state.filterOptions,
                          ot: data.value,
                        },
                      });
                      if (data.value.length >= 3) {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              ot: data.value,
                            },
                            page: 0,
                          },
                          () => this.fetchData(this.state)
                        );
                      }
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Partida</label>
                  <Input
                    type="number"
                    value={this.state.filterOptions.id || ""}
                    onChange={(event, data) => {
                      this.setState({
                        filterOptions: {
                          ...this.state.filterOptions,
                          id: data.value,
                        },
                      });
                      if (data.value.length >= 3) {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              id: data.value,
                            },
                            page: 0,
                          },
                          () => this.fetchData(this.state)
                        );
                      }
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Stage</label>
                  <Form.Dropdown
                    selection
                    options={STAGES}
                    onChange={(e, { value }) => {
                      this.setState(
                        {
                          filterOptions: {
                            ...this.state.filterOptions,
                            stageId: value,
                          },
                        },
                        (_) => this.fetchData(this.state)
                      );
                    }}
                    value={this.state.filterOptions.stageId}
                  />
                  {/* <Input
                    type="number"
                    value={this.state.filterOptions.stageId || ""}
                    onChange={(event, data) => {
                      this.setState({
                        filterOptions: {
                          ...this.state.filterOptions,
                          stageId: data.value,
                        },
                      });
                      if (data.value.length >= 1) {
                        this.setState({
                          filterOptions: {
                            ...this.state.filterOptions,
                            stageId: data.value,
                          },
                          page: 0
                        }, () => this.fetchData(this.state))
                      }
                    }}
                  /> */}
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            // { Header: "SAE", accessor: "pedido.ref_sae" },
            { Header: "OT", accessor: "pedido_id", maxWidth: 100 },
            {
              Header: "Partida",
              Cell: (row) => {
                let rowData = row.original;
                if (rowData.rodillo) {
                  if (rowData.rodillo.length) {
                    return rowData.rodillo[0].partida_id
                  } else {
                    return rowData.rodillo.partida_id
                  }
                }
                return "---";
              },
              maxWidth: 100,
            },
            { Header: "Cliente", accessor: "pedido.cliente.nombre" },
            { Header: "Producto", accessor: "producto.nombre" },
            { Header: "DN", accessor: "dn", maxWidth: 80 },
            { Header: "DE", accessor: "de", maxWidth: 80 },
            { Header: "LC", accessor: "lc", maxWidth: 80 },
            { Header: "LT", accessor: "lt", maxWidth: 80 },
            { Header: "Cantidad", accessor: "cantidad", maxWidth: 80 },
            {
              Header: "Etapa 1er Rodi.",
              id: "rodilloId",
              accessor: (d) => {
                if (d.rodillo) {
                  if (d.rodillo.length) {
                    return getStageName(d.rodillo[0].stage);
                  } else {
                    return getStageName(d.rodillo.stage);
                  }
                } else {
                  return "---";
                }
              },
            },
            { Header: "Dureza", accessor: "dureza.valor", maxWidth: 80 },
            { Header: "Kilos", accessor: "kilos", maxWidth: 100 },
            {
              Header: "Acciones",
              sortable: false,
              maxWidth: 80,
              Cell: (row) => {
                return (
                  <div>
                    <Popup
                      content="Ver más"
                      trigger={
                        <Button
                          size={"tiny"}
                          compact
                          basic
                          color="blue"
                          icon="eye"
                          onClick={(event) => this.openModal(row.original)}
                        />
                      }
                    />
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.data}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          //page={this.state.page}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState({
              columna: column,
              sortDirection: direction,
            });
          }}
        />
        <PartidaModal
          isOpened={this.state.modalOpened}
          data={this.state.currentRow}
          onClose={this.closeModal}
        />
      </div>
    );
  }
}
